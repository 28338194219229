/* eslint-disable no-restricted-globals */
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Divider,
  Empty,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Tabs,
  Typography,
  Upload,
  message
} from 'antd';
import { Header } from 'antd/es/layout/layout';
import axios from 'axios';
import { debounce } from 'lodash';
import { getType } from 'mime';
import React, { useEffect, useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { ReactComponent as Add } from '../../../../assets/icons/add.svg';
import { ReactComponent as Minus } from '../../../../assets/icons/minus.svg';

import {
  getFileSignedUrl,
  postUploadPhoto
} from '../../../../common/common-repository';
import SearchComponent from '../../../../common/components/SearchDebounce';
import { DEBOUNCE_TIME } from '../../../../common/constants';
import { clearSearchField, formValidatorRules } from '../../../../common/utils';
import { getLabs } from '../../../labs/labs-repository';
import {
  archiveTest,
  checkIfSlugExist,
  createTest,
  getEquipments,
  getSamples,
  getTestById,
  syncSandboxToLive,
  updateTest
} from '../../test-repository';
import './all-tests.less';
import ArchiveTable from './table components/Archive';
import LivePackages from './table components/LivePackages';
import LiveTests from './table components/LiveTests';
import SandBoxTable from './table components/SandBox';

const { required, number } = formValidatorRules;

function AllTests() {
  const [form] = Form.useForm();

  // STATES
  const [samples, setSamples] = useState([]);
  const [query, setQuery] = useState('');
  const [
    selectedEquipmentsWithRequirement,
    setSelectedEquipmentsWithRequirement
  ] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [showingEquipmentResults, setShowingEquipmentResults] = useState(false);
  const [fetchedEquipments, setFetchedEquipments] = useState([]);
  const [isFlat, setIsFlat] = useState(false);
  const [testTitle, setTestTitle] = useState();
  const [isSavingData, setIsSavingData] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isUpdateTest, setIsUpdateTest] = useState(false);
  const [testId, setTestId] = useState();
  const [selectedSamples, setSelectedSamples] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [uploadedImage, setUploadedImage] = useState('');
  const [uploadingImage, setUploadingImage] = useState(false);
  const [syncBtn, setSyncBtn] = useState(false);
  const [isBtn, setIsBtn] = useState(false);
  const [forceToRefetch, setForceToRefetch] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState();
  const [isPackage, setIsPackage] = useState(false);
  const [selectedLabs, setSelectedLabs] = useState([]);
  const [labs, setLabs] = useState([]);
  const [labList, setLabList] = useState([]);
  const [checkboxValidation, setCheckboxValidation] = useState(true);
  const [isDay, setIsDay] = useState(false);
  const [codeValue, setCodeValue] = useState(false);

  const fetchEquipments = async (currentSearch) => {
    if (currentSearch === '') {
      setShowingEquipmentResults(false);
      return;
    }
    setShowingEquipmentResults(true);
    setIsFetching(true);
    try {
      const response = await getEquipments({
        skip: 0,
        limit: 3,
        query: currentSearch
      });
      const { equipments } = response;
      const currentFetchedTests = [];
      for (let i = 0; i < equipments.length; i += 1) {
        const equipment = equipments[i];
        const { id } = equipment;
        let isAlreadySelected = false;
        let requiredQuantity = 0;
        for (let j = 0; j < selectedEquipmentsWithRequirement.length; j += 1) {
          if (selectedEquipmentsWithRequirement[j].equipment.id === id) {
            isAlreadySelected = true;
            requiredQuantity =
              selectedEquipmentsWithRequirement[j].requiredQuantity;
            break;
          }
        }
        currentFetchedTests.push({
          isSelected: isAlreadySelected,
          requiredQuantity,
          equipment
        });
      }
      setFetchedEquipments(currentFetchedTests);
    } catch (error) {
      //
    }
    setIsFetching(false);
  };

  // LABS FETCHING
  const fetchLabs = async () => {
    try {
      //
      const response = await getLabs({
        skip: 0,
        limit: 10000,
        query: ''
      });
      setLabs(response.labs.slice());
      setLabList(response.labs);
    } catch (error) {
      //
    }
  };
  // SAMPLES FETCHING
  const fetchSamples = async () => {
    try {
      //
      const response = await getSamples({
        limit: 10
      });

      setSamples(response.samples);
    } catch (error) {
      //
    }
  };
  useEffect(() => {
    fetchSamples();
    fetchLabs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickAdd = () => {
    let indexOfAvailableLab = -1;
    for (let i = 0; i < labs.length; i += 1) {
      if (!labs[i].isDisabled) {
        indexOfAvailableLab = i;
        break;
      }
    }
    if (indexOfAvailableLab === -1) {
      message.error('No more labs');
      return;
    }
    const currentAllLabs = labs.slice();
    currentAllLabs[indexOfAvailableLab] = {
      ...currentAllLabs[indexOfAvailableLab],
      isDisabled: true
    };
    const currentSelectedLabs = [
      ...selectedLabs,
      {
        lab: currentAllLabs[indexOfAvailableLab],
        productionCost: 0,
        isSelected: selectedLabs.length === 0 // Set isSelected to true for the first lab added
      }
    ];
    setLabs(currentAllLabs);
    setSelectedLabs(currentSelectedLabs);

    // Set the currentDefaultLabIndex to the index of the first lab added
    // setCurrentDefaultLabIndex(selectedLabs.length);
  };

  // MODAL
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCancel = () => {
    form.resetFields();
    setSelectedSamples([]);
    setLabs(labList);
    setSelectedEquipmentsWithRequirement([]);
    setUploadedImage(undefined);
    setSelectedLabs([]);
    setIsUpdateTest(false);
    clearSearchField('Equipments');
    setCodeValue(false);
    setIsPackage(false);
    setIsFlat(false);
    setIsDay(false);
    setIsModalOpen(false);
  };

  const openUpdateTestModel = async (id) => {
    setIsUpdateTest(true);
    setIsLoadingData(true);
    const test = await getTestById(id);
    const currentSelectedLabs = [];
    const idsOfCurrentlyAddedLabs = test?.productionCostWRTLab.map(
      (e) => e.lab.id
    );
    const globalLabs = [];
    for (let i = 0; i < labs.length; i += 1) {
      const currentLab = {
        ...labs[i],
        isDisabled: false
      };
      if (idsOfCurrentlyAddedLabs.includes(labs[i].id)) {
        currentLab.isDisabled = true;
        currentSelectedLabs.push({
          lab: labs[i],
          productionCost: 0,
          isSelected:
            test.productionCostWRTLab[
              idsOfCurrentlyAddedLabs.indexOf(labs[i].id)
            ].isSelected || false // Set isSelected to true for the first lab added
        });
      }
      globalLabs.push(currentLab);
    }
    setLabs(globalLabs);
    setSelectedLabs(test.productionCostWRTLab);
    setTestId(id);
    setTimeout(() => {
      setIsModalOpen(true);
      const currentSelectedSamples = [];
      for (let i = 0; i < test.samples.length; i += 1) {
        const sampleId = test.samples[i].id;
        currentSelectedSamples.push(sampleId);
      }
      const currentSelectedEquipmentsWithRequirement = [];
      for (let i = 0; i < test.equipmentsRequired.length; i += 1) {
        const equipmentsRequired = test.equipmentsRequired[i];
        currentSelectedEquipmentsWithRequirement.push(equipmentsRequired);
      }
      setSelectedEquipmentsWithRequirement(
        currentSelectedEquipmentsWithRequirement
      );
      setSelectedSamples(currentSelectedSamples);
      setTestTitle(test.title);
      if (test.isPackage) {
        setIsPackage(true);
      } else {
        setIsPackage(false);
      }
      if (test.reportGeneratedKind === 'DAYS') {
        setIsDay(true);
      } else {
        setIsDay(false);
      }
      form.setFieldsValue({
        testName: test.title,
        testDescription: test.description,
        testInstructions: test.instruction,
        offers: test.discount,
        parameters: test.parameters,
        generatedTime: test.reportGeneratedTime,
        totalPayment: test.price,
        slug: test.testSlug,
        price:
          test.kind === 'FLAT'
            ? test.price + test.discount
            : (test.price / (1 - test.discount / 100)).toFixed(2)
      });
      for (let j = 0; j < test.productionCostWRTLab.length; j += 1) {
        form.setFieldValue(
          `productionCost_${j}`,
          `${test.productionCostWRTLab[j].productionCost}`
        );
      }
      setUploadedImage(test.thumbnailUrl);
      setIsFlat(test.kind === 'FLAT');
      setIsLoadingData(false);
    }, 500);
  };

  // VALIDATING IMG AS SVG
  const beforeUpload = (file) => {
    const isLT10MB = file.size / 1024 / 1024 <= 10;
    if (!isLT10MB) {
      message.error(`File must smaller than 10MB!`);
      return !isLT10MB && Upload.LIST_IGNORE;
    }
    return false;
  };

  // BANNER UPLOAD
  const onIconUpload = async (selectedFile) => {
    const fileName = selectedFile.file.name;
    const fileType = getType(fileName);
    if (fileType === 'image/svg+xml') {
      message.error(`File must be png/jpg/jpeg`);
      return;
    }
    setUploadingImage(true);
    const response = await postUploadPhoto(fileName);
    const { signedUrl, getUrl } = response;
    const config = {
      method: 'put',
      url: signedUrl,
      headers: {
        'Content-Type': fileType
      },
      data: selectedFile.file
    };
    await axios(config);
    const url = await getFileSignedUrl(getUrl);
    setUploadedImage(url);
    setUploadingImage(false);
  };

  // SUBMIT FORM
  const submitForm = async (formValue) => {
    const {
      testName,
      testDescription,
      testInstructions,
      offers,
      totalPayment,
      productionCost,
      parameters,
      generatedTime,
      slug
    } = formValue;
    if (selectedLabs.length <= 0) {
      message.error('At least one lab is required');
      return;
    }
    if (codeValue) {
      return;
    }
    const equipmentRequired = [];
    if (selectedEquipmentsWithRequirement.length === 0) {
      message.error('At least 1 equipment is required');
      return null;
    }
    if (selectedSamples.length === 0) {
      message.error('At least 1 sample is required');
      return null;
    }
    if (parseFloat(totalPayment, 10) < 0) {
      message.error('Invalid price');
      return;
    }
    if (parseFloat(offers, 10) < 0) {
      message.error('Invalid discount');
      return;
    }
    if (parseFloat(productionCost, 10) < 0) {
      message.error('Invalid production cost');
      return;
    }
    if (parseFloat(productionCost, 10) > parseFloat(totalPayment, 10)) {
      message.error('Production cost should be less than total payment');
      return;
    }

    const atLeastOneCheckboxChecked = selectedLabs.some(
      (lab) => lab.isSelected
    );
    setCheckboxValidation(atLeastOneCheckboxChecked);
    if (!atLeastOneCheckboxChecked) {
      message.error('At least one lab should be default');
      return;
    }

    if (!checkboxValidation) {
      message.error('At least one lab should be default');
      return;
    }

    for (let n = 0; n < selectedEquipmentsWithRequirement.length; n += 1) {
      const selectedEquipment = selectedEquipmentsWithRequirement[n];
      equipmentRequired.push({
        requiredQuantity: selectedEquipment.requiredQuantity,
        equipment: selectedEquipment.equipment.id
      });
    }
    const productionCostWRTLab = [];
    for (let i = 0; i < selectedLabs.length; i += 1) {
      let isDefault = true;
      if (!selectedLabs[i].isSelected) {
        isDefault = false;
      }
      productionCostWRTLab.push({
        lab: selectedLabs[i].lab.id,
        productionCost: selectedLabs[i].productionCost,
        isSelected: isDefault
      });
    }
    const data = {
      title: testName,
      description: testDescription,
      instruction: testInstructions,
      samples: selectedSamples,
      price: parseFloat(totalPayment, 10),
      discount: parseFloat(offers, 10),
      kind: isFlat ? 'FLAT' : 'PERCENTAGE',
      equipmentRequired,
      isPackage,
      productionCostWRTLab,
      testSlug: slug
    };

    if (parameters) {
      data.parameters = parseInt(parameters, 10);
    }

    if (generatedTime) {
      data.reportGeneratedTime = parseInt(generatedTime, 10);
      data.reportGeneratedKind = isDay ? 'DAYS' : 'HOURS';
    }

    if (uploadedImage) {
      data.thumbnailUrl = uploadedImage;
    }
    try {
      setIsSavingData(true);
      await createTest({
        data
      });
      setSelectedSamples([]);
      setLabs(labList);
      setSelectedEquipmentsWithRequirement([]);
      form?.resetFields();
      setUploadedImage(undefined);
      setSelectedLabs([]);
      clearSearchField('Equipments');
      setIsPackage(false);
      setIsModalOpen(false);
      setIsSavingData(false);
      setIsUpdateTest(false);
      setCodeValue(false);
      setReloadData(!reloadData);
    } catch (error) {
      setIsSavingData(false);
    }
  };

  const handleLabCheckboxChange = (index, isChecked) => {
    // Update the selectedLabs array to set isSelected based on checkbox state
    setSelectedLabs((prevSelectedLabs) => {
      const updatedSelectedLabs = prevSelectedLabs.map((lab, i) => {
        if (i === index) {
          return {
            ...lab,
            isSelected: isChecked
          };
        }
        return {
          ...lab,
          isSelected: false
        };
      });

      // Check if at least one checkbox is checked
      const atLeastOneCheckboxChecked = updatedSelectedLabs.some(
        (lab) => lab.isSelected
      );
      setCheckboxValidation(atLeastOneCheckboxChecked);

      return updatedSelectedLabs;
    });
  };

  const onSubmitUpdateTest = async (formValue) => {
    const atLeastOneCheckboxChecked = selectedLabs.some(
      (lab) => lab.isSelected
    );
    setCheckboxValidation(atLeastOneCheckboxChecked);
    if (!atLeastOneCheckboxChecked) {
      message.error('At least one lab should be default');
      return;
    }

    const {
      testName,
      testDescription,
      testInstructions,
      offers,
      totalPayment,
      productionCost,
      parameters,
      generatedTime,
      slug
    } = formValue;
    const equipmentRequired = [];
    if (selectedEquipmentsWithRequirement.length === 0) {
      message.error('At least 1 equipment is required');
      return null;
    }
    if (codeValue) {
      return;
    }
    if (selectedSamples.length === 0) {
      message.error('At least 1 sample is required');
      return null;
    }
    if (parseFloat(totalPayment, 10) < 0) {
      message.error('Invalid price');
      return;
    }
    if (parseFloat(offers, 10) < 0) {
      message.error('Invalid discount');
      return;
    }
    if (parseFloat(productionCost, 10) < 0) {
      message.error('Invalid production cost');
      return;
    }
    if (parseFloat(productionCost, 10) > parseFloat(totalPayment, 10)) {
      message.error('Production cost should be less than total payment');
      return;
    }

    if (!checkboxValidation || undefined) {
      message.error('At least one lab should be default');
      return;
    }

    for (let n = 0; n < selectedEquipmentsWithRequirement.length; n += 1) {
      const selectedEquipment = selectedEquipmentsWithRequirement[n];
      equipmentRequired.push({
        requiredQuantity: selectedEquipment.requiredQuantity,
        equipment: selectedEquipment.equipment.id
      });
    }
    const productionCostWRTLab = [];
    for (let i = 0; i < selectedLabs.length; i += 1) {
      let isDefault = false;
      if (selectedLabs[i].isSelected === undefined) {
        isDefault = false;
      }
      if (selectedLabs[i].isSelected === false) {
        isDefault = false;
      }
      if (selectedLabs[i].isSelected === true) {
        isDefault = true;
      }
      productionCostWRTLab.push({
        lab: selectedLabs[i].lab.id,
        productionCost: selectedLabs[i].productionCost,
        isSelected: isDefault
      });
    }
    const data = {
      title: testName,
      description: testDescription,
      instruction: testInstructions,
      samples: selectedSamples,
      price: parseFloat(totalPayment, 10),
      discount: parseFloat(offers, 10),
      kind: isFlat ? 'FLAT' : 'PERCENTAGE',
      equipmentRequired,
      isPackage,
      productionCostWRTLab,
      testSlug: slug
    };
    if (uploadedImage) {
      data.thumbnailUrl = uploadedImage;
    }

    if (parameters) {
      data.parameters = parseInt(parameters, 10);
    }

    if (generatedTime) {
      data.reportGeneratedTime = parseInt(generatedTime, 10);
      data.reportGeneratedKind = isDay ? 'DAYS' : 'HOURS';
    }

    try {
      setIsSavingData(true);
      await updateTest(data, testId);
      setSelectedSamples([]);
      setLabs(labList);
      setSelectedEquipmentsWithRequirement([]);
      form?.resetFields();
      setSelectedLabs([]);
      setUploadedImage(undefined);
      clearSearchField('Equipments');
      setIsPackage(false);
      setIsModalOpen(false);
      setIsSavingData(false);
      setCodeValue(false);
      setIsUpdateTest(false);
      setReloadData(!reloadData);
    } catch (error) {
      setIsSavingData(false);
    }
  };

  const handleArchiveTest = async () => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Archiving test'
    });
    await archiveTest(testId);
    message.open({
      key,
      type: 'success',
      content: 'Test is Archived',
      duration: 1
    });
    setForceToRefetch(!forceToRefetch);
    setIsModalOpen(false);
  };

  const syncTests = async () => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Syncing...'
    });
    await syncSandboxToLive();
    setForceToRefetch(!forceToRefetch);
    setTimeout(() => {
      message.open({
        key,
        type: 'success',
        content: 'Synced!'
      });
    }, 1000);
  };

  // const handleFileUpload = async (file) => {
  //   // eslint-disable-next-line no-undef
  //   const reader = new FileReader();
  //   let bulkData;
  //   const key = 'updatable';
  //   reader.onload = async (event) => {
  //     message.open({
  //       key,
  //       type: 'loading',
  //       content: 'Adding tests'
  //     });
  //     const data = event.target.result;
  //     const workbook = XLSX.read(data, { type: 'array' });
  //     const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  //     const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

  //     const mappedArray = jsonData.map(
  //       ([
  //         Name,
  //         Description,
  //         Instructions,
  //         Samples,
  //         Price,
  //         Discount,
  //         DiscountKind,
  //         ProductionCost,
  //         EquipmentsRequired
  //       ]) => ({
  //         Name,
  //         Description,
  //         Instructions,
  //         Samples,
  //         Price,
  //         Discount,
  //         DiscountKind,
  //         ProductionCost,
  //         EquipmentsRequired
  //       })
  //     );
  //     mappedArray.shift();
  //     const newData = [];

  //     for (let i = 0; i < mappedArray.length; i += 1) {
  //       newData.push({
  //         title: mappedArray[i].Name,
  //         description: mappedArray[i].Description,
  //         instruction: mappedArray[i].Instructions,
  //         samples: getSampleData(mappedArray[i].Samples),
  //         equipmentRequired: equipmentsRequiredInBulk(
  //           mappedArray[i].EquipmentsRequired
  //         ),
  //         price: getTotalPrice(
  //           mappedArray[i].DiscountKind,
  //           parseFloat(mappedArray[i].Price),
  //           mappedArray[i].Discount
  //         ),
  //         discount: parseFloat(mappedArray[i].Discount),
  //         productionCost: mappedArray[i].ProductionCost,
  //         kind: mappedArray[i].DiscountKind
  //       });
  //     }
  //     bulkData = newData;

  //     await createTestsInBulk({ testsData: bulkData });
  //     setReloadData(!reloadData);
  //     message.open({
  //       key,
  //       type: 'success',
  //       content: 'Added successfully'
  //     });
  //   };
  //   reader.readAsArrayBuffer(file);
  // };

  // const excelBeforeUpload = (file) => {
  //   const fileType = file.type;
  //   const allowedTypes = [
  //     'application/vnd.ms-excel',
  //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  //   ];

  //   const isExcelFile = allowedTypes.includes(fileType);
  //   if (!isExcelFile) {
  //     message.error('You can only upload Excel files!');
  //     return !isExcelFile;
  //   }
  //   return false;
  // };

  // REMOVE SELECTED LAB

  const removeLab = (index) => {
    setSelectedLabs((prevSelectedLabs) => {
      const updatedSelectedLabs = prevSelectedLabs.filter(
        (_, i) => i !== index
      );

      // Update the labs state to enable the previously selected lab
      const labToEnable = selectedLabs[index].lab.id;
      setLabs((prevLabs) =>
        prevLabs.map((lab) =>
          lab.id === labToEnable ? { ...lab, isDisabled: false } : lab
        )
      );

      return updatedSelectedLabs;
    });
  };

  // Function to convert testName to slug

  // Debounced checkSlug function
  const checkSlug = debounce(async (slug) => {
    try {
      const res = await checkIfSlugExist(slug);
      setCodeValue(res); // Update state with response from API
    } catch (err) {
      setCodeValue(err); // Handle error if slug exists
    }
  }, 500); // Delay of 500ms

  const generateSlug = (testName) => {
    return testName
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^\w-]+/g, '');
  };
  const onTestNameChange = async (e) => {
    const testName = e.target.value;
    const slug = generateSlug(testName);
    form.setFieldsValue({ slug });
    checkSlug(slug);
  };

  const onChangeSlug = async (e) => {
    const slug = e.target.value;
    checkSlug(slug);
  };

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Typography.Text className="main-header-title">
              All Tests
            </Typography.Text>
            <SearchComponent
              name="Tests"
              debounceTime={DEBOUNCE_TIME}
              getData={(change) => {
                setQuery(change);
              }}
            />
          </Space>
          <div className="btn-space">
            {isBtn ? (
              <>
                {/* <Button className="excel-btn-all-tests">
                  <Upload
                    beforeUpload={excelBeforeUpload}
                    showUploadList={false}
                    accept=".xlsx , .xls"
                    onChange={(info) => {
                      handleFileUpload(info.file);
                    }}
                  >
                    Import from excel
                  </Upload>
                </Button> */}
                <Button
                  onClick={() => setIsModalOpen(true)}
                  className="create-btn"
                >
                  + Create new
                </Button>
              </>
            ) : null}
          </div>
        </div>
      </Header>
      {/* CREATE MODAL */}
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        className="create-test-modal"
        centered
        title={isUpdateTest ? testTitle : 'Create Test'}
      >
        <Divider />
        <Form
          onFinish={isUpdateTest ? onSubmitUpdateTest : submitForm}
          form={form}
          className="equipment-form"
          layout="vertical"
        >
          <Form.Item name="upload">
            <div className="profile-test uploader-class">
              <Upload
                name="upload-icon"
                onChange={onIconUpload}
                accept="image/*"
                showUploadList={false}
                beforeUpload={beforeUpload}
              >
                {uploadedImage && !uploadingImage && (
                  <>
                    <img
                      src={uploadedImage}
                      className="sample-img "
                      alt="xyz"
                    />
                    <p>Re-upload test image</p>
                  </>
                )}
                {!uploadedImage && !uploadingImage && <p>Upload test image</p>}
                {uploadingImage && <LoadingOutlined />}
                {/* <p>Upload Test Image</p> */}
              </Upload>
            </div>
          </Form.Item>
          <div className="mid">
            <Form.Item
              label="Test name"
              rules={[required]}
              onChange={onTestNameChange}
              name="testName"
            >
              <Input placeholder="Enter test name" />
            </Form.Item>
            <div className="slug-container">
              <Form.Item
                label="Slug"
                rules={[required]}
                onChange={onChangeSlug}
                name="slug"
              >
                <Input placeholder="Enter slug" />
              </Form.Item>
              {codeValue && <span>Slug already exist</span>}
            </div>
          </div>
          <div className="mid">
            Equipment Required
            <div className="tests-modal-search">
              <SearchComponent
                name="Equipments"
                debounceTime={DEBOUNCE_TIME}
                getData={fetchEquipments}
              />

              {!isFetching && showingEquipmentResults && (
                <div className="search-result" id="test-searcher">
                  {fetchedEquipments.length <= 0 ? (
                    <Empty />
                  ) : (
                    fetchedEquipments.map((currentEquipment) => {
                      const { isSelected, requiredQuantity, equipment } =
                        currentEquipment;
                      return (
                        <div className="tests-list" key={equipment.id}>
                          <Typography.Text>{equipment.name}</Typography.Text>
                          {isSelected ? (
                            <div className="flex-div">
                              <Space className="inc-dec">
                                <Button
                                  type="default"
                                  onClick={() => {
                                    const equipmentsRequired =
                                      selectedEquipmentsWithRequirement.slice();
                                    for (
                                      let i = 0;
                                      i < equipmentsRequired.length;
                                      i += 1
                                    ) {
                                      if (
                                        equipmentsRequired[i].equipment.id ===
                                        equipment.id
                                      ) {
                                        equipmentsRequired[i] = {
                                          ...equipmentsRequired[i],
                                          requiredQuantity:
                                            equipmentsRequired[i]
                                              .requiredQuantity + 1
                                        };
                                        break;
                                      }
                                    }
                                    const tempFetchedTests =
                                      fetchedEquipments.slice();
                                    for (
                                      let i = 0;
                                      i < tempFetchedTests.length;
                                      i += 1
                                    ) {
                                      if (
                                        tempFetchedTests[i].equipment.id ===
                                        equipment.id
                                      ) {
                                        tempFetchedTests[i] = {
                                          ...tempFetchedTests[i],
                                          requiredQuantity:
                                            tempFetchedTests[i]
                                              .requiredQuantity + 1
                                        };
                                        break;
                                      }
                                    }
                                    setSelectedEquipmentsWithRequirement(
                                      equipmentsRequired
                                    );
                                    setFetchedEquipments(tempFetchedTests);
                                  }}
                                  className="inc"
                                >
                                  <Add />
                                </Button>
                                <Button type="default" disabled>
                                  {requiredQuantity}
                                </Button>
                                <Button
                                  type="default"
                                  className="dec"
                                  onClick={() => {
                                    const equipmentsRequired =
                                      selectedEquipmentsWithRequirement.slice();
                                    for (
                                      let i = 0;
                                      i < equipmentsRequired.length;
                                      i += 1
                                    ) {
                                      if (
                                        equipmentsRequired[i].equipment.id ===
                                        equipment.id
                                      ) {
                                        equipmentsRequired[i] = {
                                          ...equipmentsRequired[i],
                                          requiredQuantity:
                                            equipmentsRequired[i]
                                              .requiredQuantity - 1
                                        };
                                        break;
                                      }
                                    }
                                    const tempFetchedTests =
                                      fetchedEquipments.slice();
                                    for (
                                      let i = 0;
                                      i < tempFetchedTests.length;
                                      i += 1
                                    ) {
                                      if (
                                        tempFetchedTests[i].equipment.id ===
                                        equipment.id
                                      ) {
                                        tempFetchedTests[i] = {
                                          ...tempFetchedTests[i],
                                          requiredQuantity:
                                            tempFetchedTests[i]
                                              .requiredQuantity - 1
                                        };
                                        break;
                                      }
                                    }
                                    setSelectedEquipmentsWithRequirement(
                                      equipmentsRequired
                                    );
                                    setFetchedEquipments(tempFetchedTests);
                                  }}
                                  disabled={requiredQuantity === 1}
                                >
                                  <Minus />
                                </Button>
                              </Space>
                              <Button
                                type="text"
                                onClick={() => {
                                  const equipmentsRequired =
                                    selectedEquipmentsWithRequirement.slice();
                                  for (
                                    let i = 0;
                                    i < equipmentsRequired.length;
                                    i += 1
                                  ) {
                                    if (
                                      equipmentsRequired[i].equipment.id ===
                                      equipment.id
                                    ) {
                                      equipmentsRequired.splice(i, 1);
                                      break;
                                    }
                                  }
                                  const tempFetchedTests =
                                    fetchedEquipments.slice();
                                  for (
                                    let i = 0;
                                    i < tempFetchedTests.length;
                                    i += 1
                                  ) {
                                    if (
                                      tempFetchedTests[i].equipment.id ===
                                      equipment.id
                                    ) {
                                      tempFetchedTests[i] = {
                                        ...tempFetchedTests[i],
                                        isSelected: false
                                      };
                                      break;
                                    }
                                  }
                                  setSelectedEquipmentsWithRequirement(
                                    equipmentsRequired
                                  );
                                  setFetchedEquipments(tempFetchedTests);
                                }}
                              >
                                Remove
                              </Button>
                            </div>
                          ) : (
                            <Button
                              onClick={() => {
                                const equipmentsRequired =
                                  selectedEquipmentsWithRequirement.slice();
                                equipmentsRequired.push({
                                  equipment,
                                  requiredQuantity: 1
                                });
                                const tempFetchedTests =
                                  fetchedEquipments.slice();
                                for (
                                  let i = 0;
                                  i < tempFetchedTests.length;
                                  i += 1
                                ) {
                                  if (
                                    tempFetchedTests[i].equipment.id ===
                                    equipment.id
                                  ) {
                                    tempFetchedTests[i] = {
                                      ...tempFetchedTests[i],
                                      isSelected: true,
                                      requiredQuantity: 1
                                    };
                                    break;
                                  }
                                }
                                setSelectedEquipmentsWithRequirement(
                                  equipmentsRequired
                                );
                                setFetchedEquipments(tempFetchedTests);
                              }}
                              type="default"
                            >
                              Add
                            </Button>
                          )}
                        </div>
                      );
                    })
                  )}
                </div>
              )}
            </div>
            {selectedEquipmentsWithRequirement.length <= 0 ? null : (
              <div className="selected-required-equipment-container">
                {selectedEquipmentsWithRequirement.map((currentEquipment) => {
                  const { equipment, requiredQuantity } = currentEquipment;
                  return (
                    <div className="equipment-list" key={equipment.name}>
                      <Typography.Text>{equipment.name}</Typography.Text>
                      <div className="flex-div">
                        <Space className="inc-dec">
                          <Button
                            type="default"
                            onClick={() => {
                              const equipmentsRequired =
                                selectedEquipmentsWithRequirement.slice();
                              for (
                                let i = 0;
                                i < equipmentsRequired.length;
                                i += 1
                              ) {
                                if (
                                  equipmentsRequired[i].equipment.id ===
                                  equipment.id
                                ) {
                                  equipmentsRequired[i] = {
                                    ...equipmentsRequired[i],
                                    requiredQuantity:
                                      equipmentsRequired[i].requiredQuantity + 1
                                  };
                                  break;
                                }
                              }
                              setSelectedEquipmentsWithRequirement(
                                equipmentsRequired
                              );
                            }}
                            className="inc"
                          >
                            <Add />
                          </Button>
                          <Button type="default" disabled>
                            {requiredQuantity}
                          </Button>
                          <Button
                            type="default"
                            className="dec"
                            onClick={() => {
                              const equipmentsRequired =
                                selectedEquipmentsWithRequirement.slice();
                              for (
                                let i = 0;
                                i < equipmentsRequired.length;
                                i += 1
                              ) {
                                if (
                                  equipmentsRequired[i].equipment.id ===
                                  equipment.id
                                ) {
                                  equipmentsRequired[i] = {
                                    ...equipmentsRequired[i],
                                    requiredQuantity:
                                      equipmentsRequired[i].requiredQuantity - 1
                                  };
                                  break;
                                }
                              }

                              setSelectedEquipmentsWithRequirement(
                                equipmentsRequired
                              );
                            }}
                            disabled={requiredQuantity === 1}
                          >
                            <Minus />
                          </Button>
                        </Space>
                        <Button
                          type="text"
                          onClick={() => {
                            const equipmentsRequired =
                              selectedEquipmentsWithRequirement.slice();
                            for (
                              let i = 0;
                              i < equipmentsRequired.length;
                              i += 1
                            ) {
                              if (
                                equipmentsRequired[i].equipment.id ===
                                equipment.id
                              ) {
                                equipmentsRequired.splice(i, 1);
                                break;
                              }
                            }
                            setSelectedEquipmentsWithRequirement(
                              equipmentsRequired
                            );
                          }}
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <Form.Item
              label="Test description"
              rules={[required]}
              name="testDescription"
            >
              <Input.TextArea placeholder="Enter test description" />
            </Form.Item>
            <div className="test-instructions">
              <Form.Item
                rules={[required]}
                label="Test instructions"
                name="testInstructions"
              >
                <Input.TextArea placeholder="Enter test instructions" />
              </Form.Item>
              <Button
                type="link"
                onClick={() => {
                  const url =
                    'https://onlinehtmleditor.dev/collaboration/#doce4e86578ac';
                  // eslint-disable-next-line no-undef
                  window.open(url, '_blank');
                }}
              >
                Click to generate instructions
              </Button>
            </div>
          </div>
          <div className="spacer-btns">
            <div className="sample-name">
              {samples
                ? samples.map((sample) => {
                    return (
                      <div key={sample.id} className="sample-info">
                        <Checkbox
                          onChange={(e) => {
                            if (e.target.checked) {
                              const currentSelectedSamples =
                                selectedSamples.slice();
                              currentSelectedSamples.push(sample.id);
                              setSelectedSamples(currentSelectedSamples);
                            } else {
                              const currentSelectedSamples =
                                selectedSamples.slice();
                              for (
                                let i = 0;
                                i < currentSelectedSamples.length;
                                i += 1
                              ) {
                                if (currentSelectedSamples[i] === sample.id) {
                                  currentSelectedSamples.splice(i, 1);
                                  break;
                                }
                              }
                              setSelectedSamples(currentSelectedSamples);
                            }
                          }}
                          checked={selectedSamples.includes(sample.id)}
                          value={sample.id}
                        >
                          {sample.name}
                          <img
                            src={sample.thumbnail}
                            className="sample-img-checkbox"
                            alt={sample.name}
                          />
                        </Checkbox>
                      </div>
                    );
                  })
                : null}
            </div>
            <div
              style={{
                width: '150px'
              }}
            >
              <Checkbox
                checked={isPackage}
                onChange={(e) => {
                  if (e.target.checked) {
                    setIsPackage(true);
                  } else {
                    setIsPackage(false);
                  }
                }}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                Package
              </Checkbox>
            </div>
          </div>

          <div className="total-price">
            <Form.Item label="Price" rules={[required]} name="price">
              <Input
                onChange={() => {
                  form?.resetFields(['offers', 'totalPayment']);
                }}
              />
            </Form.Item>
            <Form.Item
              rules={[required]}
              className="offer-field"
              label={
                <Space className="offer-container">
                  Discount
                  <Space className="flex-div">
                    <div
                      className={!isFlat ? 'active' : ''}
                      onClick={() => {
                        setIsFlat(false);
                        form?.resetFields(['offers', 'totalPayment']);
                      }}
                    >
                      %
                    </div>
                    <div
                      className={isFlat ? 'active' : ''}
                      onClick={() => {
                        setIsFlat(true);
                        form?.resetFields(['offers', 'totalPayment']);
                      }}
                    >
                      Rs
                    </div>
                  </Space>
                </Space>
              }
              name="offers"
            >
              <Input
                onChange={(change) => {
                  const discount = parseFloat(change.target.value, 10);

                  if (Number.isNaN(discount)) {
                    form?.resetFields(['totalPayment']);
                    return;
                  }
                  const price = parseFloat(
                    form?.getFieldValue('price') || 0,
                    100
                  );
                  if (!isFlat) {
                    if (discount > 100) {
                      form?.resetFields(['offers']);
                      form?.resetFields(['totalPayment']);
                      return null;
                    }
                    const discountInPer = (discount / 100) * price;
                    const totalPayment = (price - discountInPer).toFixed(2);
                    form?.setFieldValue('totalPayment', totalPayment);
                  } else if (discount > price) {
                    form?.resetFields(['totalPayment']);
                    return null;
                  } else {
                    form?.setFieldValue('totalPayment', price - discount);
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              rules={[required]}
              label="Total payment"
              name="totalPayment"
            >
              <Input
                disabled
                value={selectedPayment}
                onChange={(e) => {
                  setSelectedPayment(e.target.value);
                }}
              />
            </Form.Item>
          </div>
          <div className="parameters">
            <Form.Item label="Parameters" rules={[number]} name="parameters">
              <Input />
            </Form.Item>
            <Form.Item
              rules={[number]}
              className="offer-field param-input"
              label={
                <Space className="offer-container">
                  Report generation time
                  <Space className="flex-div">
                    <div
                      className={!isDay ? 'active' : ''}
                      onClick={() => {
                        setIsDay(false);
                        form?.resetFields(['generatedTime']);
                      }}
                    >
                      Hr
                    </div>
                    <div
                      className={isDay ? 'active' : ''}
                      onClick={() => {
                        setIsDay(true);
                        form?.resetFields(['generatedTime']);
                      }}
                    >
                      Days
                    </div>
                  </Space>
                </Space>
              }
              name="generatedTime"
            >
              <Input suffix={isDay ? 'Days' : 'Hours'} />
            </Form.Item>
          </div>
          {selectedLabs
            ? selectedLabs.map((selectedLab, index) => {
                return (
                  <div className="selection" key={selectedLab.lab.id}>
                    <div>
                      <Form.Item
                        label="Select labs"
                        name={`selectedLab_${index}`}
                      >
                        <Select
                          placeholder="Select equipment"
                          defaultValue={selectedLab.lab.id}
                          options={labs.map((lab) => {
                            return {
                              value: lab.id,
                              label: lab.displayName,
                              disabled: lab.isDisabled
                            };
                          })}
                          onChange={(id) => {
                            const currentAllLabs = labs.slice();
                            let newLabIndex = 0;
                            for (let i = 0; i < currentAllLabs.length; i += 1) {
                              if (currentAllLabs[i].id === id) {
                                newLabIndex = i;
                                currentAllLabs[i] = {
                                  ...currentAllLabs[i],
                                  isDisabled: true
                                };
                              }
                              if (currentAllLabs[i].id === selectedLab.lab.id) {
                                currentAllLabs[i] = {
                                  ...currentAllLabs[i],
                                  isDisabled: false
                                };
                              }
                            }

                            setSelectedLabs((prevSelectedLabs) => {
                              const updatedSelectedLabs = [...prevSelectedLabs];
                              updatedSelectedLabs[index] = {
                                lab: currentAllLabs[newLabIndex],
                                productionCost: 0 // Set default production cost here
                              };
                              return updatedSelectedLabs;
                            });

                            setLabs(currentAllLabs);

                            form.setFieldValue(`productionCost_${index}`, ``);
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div className="amount-req production-cost-container">
                      <div>
                        <Form.Item
                          label="Production Cost"
                          name={`productionCost_${index}`}
                          validateTrigger={['onChange']}
                          rules={[
                            required,
                            {
                              validator: (_, value) => {
                                if (
                                  parseFloat(value, 10) >=
                                  parseFloat(
                                    form.getFieldValue('totalPayment'),
                                    10
                                  )
                                ) {
                                  // eslint-disable-next-line prefer-promise-reject-errors
                                  return Promise.reject(
                                    'Should be less than price'
                                  );
                                }
                                return Promise.resolve();
                              }
                            }
                          ]}
                        >
                          <Input
                            value={selectedLab.productionCost}
                            onChange={(e) => {
                              const value = parseFloat(e.target.value);
                              setSelectedLabs((prevSelectedLabs) => {
                                const updatedSelectedLabs = [
                                  ...prevSelectedLabs
                                ];
                                updatedSelectedLabs[index] = {
                                  ...updatedSelectedLabs[index],
                                  productionCost: isNaN(value) ? 0 : value
                                };
                                return updatedSelectedLabs;
                              });
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="labs-btn-handler">
                      <Checkbox
                        onChange={(e) =>
                          handleLabCheckboxChange(index, e.target.checked)
                        }
                        checked={selectedLab.isSelected}
                        value={selectedLab.isSelected}
                      />
                      {/* <Button type="danger"> */}
                      <AiOutlineCloseCircle
                        className="remove-icon"
                        color="red"
                        size={20}
                        onClick={() => removeLab(index)}
                      />
                      {/* </Button> */}
                    </div>
                  </div>
                );
              })
            : ''}

          <Button
            onClick={onClickAdd}
            type="dashed"
            className="add-equipment-modal"
          >
            + ADD PRODUCTION COST
          </Button>
          <Divider />
          <div className="create-wrapper">
            {isUpdateTest ? (
              <Button
                // loading={isSavingData}
                onClick={handleArchiveTest}
                className="disable-btn-modal"
              >
                Archive Test
              </Button>
            ) : null}
            <Button
              loading={isSavingData}
              disabled={samples.length === 0}
              htmlType="submit"
              className="create-btn-modal"
            >
              {isUpdateTest ? 'Save' : 'Create'}
            </Button>
            {samples?.length === 0 ? (
              <span className="warning-note">
                Please upload samples before further procedure.
              </span>
            ) : null}
          </div>
        </Form>
      </Modal>
      <div className="content">
        <div className="tabs-header">
          <Tabs
            onChange={(key) => {
              setForceToRefetch(!forceToRefetch);
              if (key === '3') {
                setSyncBtn(true);
                setIsBtn(true);
              } else {
                setSyncBtn(false);
                setIsBtn(false);
              }
            }}
            items={[
              {
                key: '1',
                label: `Live Packages`,
                forceRender: true,
                children: (
                  <LivePackages
                    openUpdateTestModel={openUpdateTestModel}
                    parentWantToLoad={isLoadingData}
                    reloadData={reloadData}
                    query={query}
                    forceToRefetch={forceToRefetch}
                  />
                )
              },
              {
                key: '2',
                label: `Live Tests`,
                forceRender: true,
                children: (
                  <LiveTests
                    openUpdateTestModel={openUpdateTestModel}
                    parentWantToLoad={isLoadingData}
                    reloadData={reloadData}
                    query={query}
                    forceToRefetch={forceToRefetch}
                  />
                )
              },
              {
                key: '3',
                label: `Sandbox Tests`,
                forceRender: true,
                children: (
                  <SandBoxTable
                    openUpdateTestModel={openUpdateTestModel}
                    parentWantToLoad={isLoadingData}
                    reloadData={reloadData}
                    query={query}
                    forceToRefetch={forceToRefetch}
                  />
                )
              },
              {
                key: '4',
                label: `Archive`,
                forceRender: true,
                children: (
                  <ArchiveTable
                    openUpdateTestModel={openUpdateTestModel}
                    parentWantToLoad={isLoadingData}
                    reloadData={reloadData}
                    query={query}
                    forceToRefetch={forceToRefetch}
                  />
                )
              }
            ]}
          />
        </div>
        {syncBtn ? (
          <div className="sync">
            <Button type="text" onClick={() => syncTests()}>
              <ReloadOutlined /> Sync sandbox to live
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
}
export default AllTests;
