/* eslint-disable no-constant-condition */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */

import {
  Button,
  Divider,
  Empty,
  Form,
  Input,
  message,
  Modal,
  Space,
  TimePicker,
  Tooltip,
  Typography,
  Upload
} from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  CloseCircleOutlined,
  LoadingOutlined,
  WarningFilled
} from '@ant-design/icons';
import { Header } from 'antd/es/layout/layout';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { getDatabase, onValue, ref } from 'firebase/database';
import { getType } from 'mime';
import React, { useEffect, useState } from 'react';
import { AiOutlineReload, AiOutlineShareAlt } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as UploadImage } from '../../assets/upload.svg';
import CommonTable from '../../common/components/CommonTable';
import CountryCodePicker from '../../common/components/CountryCodePicker';
import LoaderComponent from '../../common/components/LoaderComponent';
import SearchComponent from '../../common/components/SearchDebounce';
import config from '../../common/config';
import {
  DEBOUNCE_TIME,
  GLOBAL_PAGINATION_LIMIT,
  MOBILE_REGEX,
  ROUTES
} from '../../common/constants';
import { clearSearchField, formValidatorRules } from '../../common/utils';

import {
  getFileSignedUrl,
  postUploadPhoto
} from '../../common/common-repository';
import firebaseConfig from '../../common/firebase-config';
import {
  createSystemDoctor,
  getDoctorProfileUrl,
  getDoctorsList,
  refreshAllDoctors,
  refreshDoctorByID,
  toggleDoctorEnable
} from './doctor-repository';

function AllDoctors() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [locationForm] = Form.useForm();
  const { required, number } = formValidatorRules;
  const limit = GLOBAL_PAGINATION_LIMIT;
  const timeFormat = 'hh:mm a';

  const [loadingData, setLoadingData] = useState(true);
  const [skipVal, setSkipVal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [doctors, setDoctors] = useState([]);
  const [query, setQuery] = useState('');
  const [paginationProp, setPaginationProp] = useState();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isSavingData, setIsSavingData] = useState(false);
  const [openDisableUser, setOpenDisableUser] = useState(false);
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState();
  const [uploadedImage, setUploadedImage] = useState('');
  const [uploadingImage, setUploadingImage] = useState(false);
  const [snapshotData, setSnapshotData] = useState();
  const [locationObjects, setLocationObjects] = useState([]);
  const [openLocationPicker, setOpenLocationPicker] = useState(false);
  const [predictions, setPredictions] = useState([]);
  const [loadingSearchResults, setLoadingSearchResults] = useState(false);
  const [currentClinicIndex, setCurrentClinicIndex] = useState(null);

  const fetchDoctorList = async ({ currentSkip, currentQuery }) => {
    try {
      //
      const response = await getDoctorsList({
        skip: currentSkip,
        limit: limit,
        query: currentQuery
      });
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setDoctors(response.doctors.slice());
    } catch (error) {
      setLoading(false);
      setLoadingData(false);
    }
    setLoading(false);
    setLoadingData(false);
  };
  useEffect(() => {
    fetchDoctorList({
      currentQuery: query,
      currentSkip: skipVal
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TOGGLE ENABLE DISABLE

  const toggleEnabled = async (index) => {
    doctors[index] = {
      ...doctors[index],
      isDisabled: !(doctors[index].isDisabled || false)
    };
    await toggleDoctorEnable(doctors[index].id);
    setDoctors(doctors.slice());
  };

  // FUNCTIONS
  const triggerRealtimeLoading = () => {
    const db = getDatabase(firebaseConfig);
    const dbRef = ref(db, 'digitalProfiles');
    onValue(dbRef, (snapshot) => {
      const data = snapshot.val();
      setSnapshotData(data);
    });
  };

  const progressCounter = () => {
    return `Processing : ${snapshotData?.completed}/${snapshotData?.total}`;
  };

  useEffect(() => {
    triggerRealtimeLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    form.resetFields();
    setUploadedImage(undefined);
    setIsCreateModalOpen(false);
  };

  const handleRefreshDoctorProfile = async (doctorID) => {
    const key = 'updatable';
    try {
      message.open({
        key,
        type: 'loading',
        content: 'Processing'
      });
      await refreshDoctorByID(doctorID);
      message.open({
        key,
        type: 'success',
        content: 'Profile updated.'
      });
      setTimeout(() => {
        message.destroy(key);
      }, 500);
    } catch (error) {
      message.open({
        key,
        type: 'error',
        content: 'Failed to update profile'
      });
      setTimeout(() => {
        message.destroy(key);
      }, 500);
    }
  };

  const refreshAllDocProfiles = async () => {
    try {
      await refreshAllDoctors();
      message.success('Profiles updated');
    } catch (error) {
      message.error('Failed to refresh profiles');
    }
  };

  const openInNewTab = async (ID) => {
    try {
      message.loading('Opening Profile', 1);
      const response = await getDoctorProfileUrl(ID);
      if (response) {
        const { publicUrl } = response;
        // eslint-disable-next-line no-undef
        window.open(publicUrl, '_blank', 'noreferrer');
      }
    } catch (error) {
      message.error('Failed to find profile');
    }
  };

  const handleLocationPickerCancel = () => {
    locationForm.resetFields();
    setOpenLocationPicker(false);
    clearSearchField('Location');
  };

  const fetchAddresses = async (value) => {
    const auth = getAuth();
    let userToken = '';
    const currentUser = auth?.currentUser;
    if (currentUser) {
      userToken = await currentUser.getIdToken(true);
    }
    const request = {
      method: 'get',
      url: `${config.SERVER_URL}api/get-location-suggestions?search=${value}`,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    };
    setLoadingSearchResults(true);
    const response = await axios(request);
    setPredictions(response.data.predictions);
    setLoadingSearchResults(false);
  };

  const selectAddress = async (placeId) => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Location is getting selected'
    });

    const auth = getAuth();
    let userToken = '';
    const currentUser = auth?.currentUser;
    if (currentUser) {
      userToken = await currentUser.getIdToken(true);
    }

    const request = {
      method: 'get',
      url: `${config.SERVER_URL}api/decode-location-with-id?id=${placeId}`,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    };

    const response = await axios(request);
    const location = response.data;

    const locationData = {
      address: location.address,
      latitude: location.latitude,
      longitude: location.longitude
    };

    if (currentClinicIndex !== null) {
      // Set clinic location directly in form fields
      form.setFieldsValue({
        clinicDetails: form
          .getFieldValue('clinicDetails')
          .map((clinic, idx) =>
            idx === currentClinicIndex
              ? { ...clinic, clinicAddress: locationData.address }
              : clinic
          )
      });
    }

    // Update locationObjects array with the new locationData
    setLocationObjects((prev) => {
      const updatedLocations = [...prev];
      updatedLocations[currentClinicIndex] = locationData;
      return updatedLocations;
    });

    // Trigger form validation to remove the required message
    form.validateFields([
      ['clinicDetails', currentClinicIndex, 'clinicAddress']
    ]);

    handleLocationPickerCancel();
    message.open({
      key,
      type: 'success',
      content: 'Location picked',
      duration: 1
    });
  };

  // COLUMNS
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (value, record) => {
        // eslint-disable-next-line no-lone-blocks
        {
          if (value.length < 20) {
            return (
              <Typography
                className="clickable-title"
                onClick={() => {
                  const url = `${ROUTES.DOCTORS}/${record.id}`;
                  navigate(url);
                }}
              >
                {value}
              </Typography>
            );
          }

          return (
            <Typography
              className="clickable-title"
              onClick={() => {
                const url = `${ROUTES.DOCTORS}/${record.id}`;
                navigate(url);
              }}
            >
              <Tooltip title={value} color="#232e78">
                {value.slice(0, 20)}...
              </Tooltip>
            </Typography>
          );
        }
      }
    },
    {
      title: 'Designation',
      dataIndex: 'designation',
      key: 'designation',
      render: (value) => {
        return value;
      }
    },
    {
      title: 'Mobile number',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',
      render: (value, record) => {
        return (
          <p>
            {record.countryCode}-{value}
          </p>
        );
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (value) => {
        return value;
      }
    },
    {
      title: 'Commission ',
      dataIndex: 'commissionPercentage',
      key: 'commissionPercentage',
      render: (value) => {
        return `${value}%`;
      }
    },
    {
      title: 'Profile Views ',
      dataIndex: 'profileViewCount',
      key: 'profileViewCount',
      render: (value) => {
        return value;
      }
    },
    {
      title: 'Action',
      dataIndex: 'isDisabled',
      key: 'isDisabled',
      render: (value, record, index) => {
        const { id } = record;
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '12px'
            }}
          >
            {value ? (
              <span
                className="disable-class"
                onClick={() => {
                  toggleEnabled(index);
                }}
              >
                Disabled
              </span>
            ) : (
              <span
                className="enable-class"
                onClick={() => {
                  setCurrentSelectedIndex(index);
                  setTimeout(() => {
                    setOpenDisableUser(true);
                  }, 300);
                }}
              >
                Enabled
              </span>
            )}
            <AiOutlineReload
              style={{
                cursor: 'pointer'
              }}
              onClick={() => {
                handleRefreshDoctorProfile(id);
              }}
            />

            <AiOutlineShareAlt
              style={{
                cursor: 'pointer'
              }}
              onClick={() => {
                openInNewTab(id);
              }}
            />
          </div>
        );
      }
    }
  ];

  const onSubmitForm = async (formValue) => {
    const {
      name,
      designation,
      email,
      countryCode,
      mobileNo,
      commissionValue,
      password,
      experience,
      clinicDetails = [], // Initialize as empty array if undefined
      instagram,
      facebook,
      linkedin,
      youtube
    } = formValue;

    if (mobileNo.length !== 10) {
      message.error('Invalid mobile number');
      return;
    }
    if (!mobileNo.match(MOBILE_REGEX)) {
      message.error('Invalid mobile number');
      return;
    }

    if (clinicDetails.length < 1) {
      message.error('Minimum 1 clinic is required');
      return;
    }

    const nullLocation = {
      latitude: 0,
      longitude: 0
    };

    const payload = {
      name,
      designation,
      email,
      countryCode: countryCode || '+91',
      mobileNumber: mobileNo,
      commissionPercentage: parseInt(commissionValue, 10),
      password,
      experience: parseInt(experience, 10),
      clinicDetails: clinicDetails.map((clinic, index) => ({
        clinicName: clinic.clinicName,
        clinicAddress: clinic.clinicAddress,
        morningTime: clinic.morningTime
          ? `${clinic.morningTime[0].format(
              timeFormat
            )} - ${clinic.morningTime[1].format(timeFormat)}`
          : null,
        eveningTime: clinic.eveningTime
          ? `${clinic.eveningTime[0].format(
              timeFormat
            )} - ${clinic.eveningTime[1].format(timeFormat)}`
          : null,
        location: locationObjects[index]
          ? {
              latitude: locationObjects[index].latitude,
              longitude: locationObjects[index].longitude
            }
          : nullLocation // Attach corresponding location object
      })),
      instagram,
      facebook,
      linkedin,
      youtube,
      profilePicture: uploadedImage || null
    };
    try {
      setIsSavingData(true);
      const createdDoctor = await createSystemDoctor(payload);
      if (createdDoctor) {
        if (
          doctors.length === 0 ||
          doctors.length < limit ||
          doctors[doctors.length - 1].serialNumber + 1 ===
            createdDoctor.serialNumber
        ) {
          const updatedDoctor = doctors.slice();
          updatedDoctor.push(createdDoctor);
          setDoctors(updatedDoctor.slice());
        }
        setIsSavingData(false);
        handleCancel();
      }
      setIsSavingData(false);
    } catch (error) {
      setIsSavingData(false);
    } finally {
      setIsSavingData(false);
    }
  };

  // VALIDATING IMG AS SVG
  const beforeUpload = (file) => {
    const isLT10MB = file.size / 1024 / 1024 <= 10;
    if (!isLT10MB) {
      message.error('Image must smaller than 10MB!');
      return !isLT10MB && Upload.LIST_IGNORE;
    }
    return false;
  };

  // BANNER UPLOAD
  const onIconUpload = async (selectedFile) => {
    const fileName = selectedFile.file.name;
    const fileType = getType(fileName);
    if (fileType === 'image/svg+xml') {
      message.error(`File must be png/jpg/jpeg`);
      return;
    }
    setUploadingImage(true);
    const response = await postUploadPhoto(fileName);
    const { signedUrl, getUrl } = response;
    const configFile = {
      method: 'put',
      url: signedUrl,
      headers: {
        'Content-Type': fileType
      },
      data: selectedFile.file
    };
    await axios(configFile);
    const url = await getFileSignedUrl(getUrl);
    setUploadedImage(url);
    setUploadingImage(false);
  };

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Typography.Text className="main-header-title">
              Doctors
            </Typography.Text>
            <SearchComponent
              name="Doctors"
              debounceTime={DEBOUNCE_TIME}
              getData={(value) => {
                setQuery(value);
                setLoadingData(true);
                fetchDoctorList({
                  currentQuery: value,
                  currentSkip: skipVal
                });
              }}
            />
          </Space>
          <div
            style={{
              display: 'flex',
              gap: '10px'
            }}
          >
            <Button
              className="orderHistory"
              onClick={refreshAllDocProfiles}
              loading={snapshotData?.inProgress}
            >
              {snapshotData?.inProgress
                ? progressCounter()
                : 'Refresh All Profile'}
            </Button>
            <Button
              className="create-btn"
              onClick={() => setIsCreateModalOpen(true)}
            >
              + Create New
            </Button>
          </div>
        </div>
        {/* CREATE MODAL */}
        <Modal
          open={isCreateModalOpen}
          onCancel={handleCancel}
          className="create-test-modal"
          centered
          title="Create Doctor"
        >
          <Divider />
          <Form form={form} onFinish={onSubmitForm} layout="vertical">
            <Form.Item name="upload">
              <div className="profile doc-profile">
                <Upload
                  name="upload-icon"
                  onChange={onIconUpload}
                  accept="image/*"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                >
                  {uploadedImage && !uploadingImage && (
                    <img
                      className="uploaded-image"
                      src={uploadedImage}
                      alt="xyz"
                    />
                  )}
                  {!uploadedImage && !uploadingImage && (
                    <UploadImage className="sample-image doc-image" />
                  )}
                  {uploadingImage && <LoadingOutlined />}
                  {!uploadedImage ? (
                    <p>Upload profile image</p>
                  ) : (
                    <p>Reupload profile image</p>
                  )}
                </Upload>
              </div>
            </Form.Item>
            <div className="row-one">
              <Form.Item rules={[required]} name="name" label="Name">
                <Input type="text" />
              </Form.Item>
              <Form.Item
                rules={[required]}
                name="designation"
                label="Designation"
              >
                <Input type="text" />
              </Form.Item>
            </div>
            <div className="row-one">
              <Form.Item rules={[required]} name="email" label="Email Id">
                <Input type="email" />
              </Form.Item>
              <Form.Item
                rules={[
                  required,
                  number,
                  () => ({
                    validator(rule, value) {
                      if (value.length > 10) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise?.reject('Number limit exceeded');
                      }
                      return Promise?.resolve();
                    }
                  })
                ]}
                className="mobile-number"
                name="mobileNo"
                label="Mobile number"
              >
                <Input
                  type="text"
                  style={{ width: '100%' }}
                  addonBefore={<CountryCodePicker name="countryCode" />}
                />
              </Form.Item>
            </div>
            <div className="row-one doc-class">
              <Form.Item
                rules={[required, number]}
                name="commissionValue"
                label="Commission %"
              >
                <Input type="text" maxLength={2} />
              </Form.Item>
              <Form.Item label="Password" name="password" rules={[required]}>
                <Input.Password className="password-inp-doc" name="password" />
              </Form.Item>
            </div>
            <div className="row-one doc-class">
              <Form.Item rules={[number]} name="experience" label="Experience">
                <Input type="text" maxLength={2} />
              </Form.Item>
            </div>
            <div
              style={{
                fontSize: '16px',
                fontWeight: '600'
              }}
            >
              Clinic Details
            </div>
            <Form.List name="clinicDetails">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <div key={key} className="clinic-details-group">
                      <div className="row-one">
                        <Form.Item
                          {...restField}
                          name={[name, 'clinicName']}
                          label="Clinic Name"
                          rules={[required]}
                        >
                          <Input type="text" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'clinicAddress']}
                          label="Clinic Address"
                          rules={[required]}
                        >
                          <Input type="text" />
                        </Form.Item>
                      </div>
                      <div className="row-one doc-time-row">
                        <Form.Item
                          {...restField}
                          name={[name, 'morningTime']}
                          label="Morning Time"
                          rules={[required]}
                        >
                          <TimePicker.RangePicker format={timeFormat} />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'eveningTime']}
                          label="Evening Time"
                        >
                          <TimePicker.RangePicker format={timeFormat} />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'location']}
                          label="Location address"
                          // rules={[
                          //   { required: true, message: 'Location is required' }
                          // ]}
                        >
                          <Button
                            style={{
                              width: '100%',
                              height: '48px',
                              borderRadius: '10px'
                            }}
                            onClick={() => {
                              setCurrentClinicIndex(name);
                              setOpenLocationPicker(true);
                            }}
                          >
                            Select Location
                            {/* {form.getFieldValue([
                              'clinicDetails',
                              name,
                              'location'
                            ])
                              ? 'Change location'
                              : 'Select location'} */}
                          </Button>
                        </Form.Item>
                      </div>
                      <Button
                        className="remove-clinic"
                        onClick={() => remove(name)}
                        style={{ marginBottom: '10px' }}
                      >
                        Remove Clinic
                      </Button>
                      <Divider />
                    </div>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      className="add-clinic-btn"
                      onClick={() => add()}
                      block
                    >
                      + Add Clinic Details
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <div
              style={{
                fontSize: '16px',
                fontWeight: '600'
              }}
            >
              Socials
            </div>
            <div className="row-one ">
              <Form.Item name="instagram" label="Instagram">
                <Input type="text" />
              </Form.Item>
              <Form.Item name="facebook" label="Facebook">
                <Input type="text" />
              </Form.Item>
            </div>
            <div className="row-one ">
              <Form.Item name="linkedin" label="LinkedIn">
                <Input type="text" />
              </Form.Item>
              <Form.Item name="youtube" label="Youtube">
                <Input type="text" />
              </Form.Item>
            </div>

            <Divider />
            <Button
              loading={isSavingData}
              htmlType="submit"
              className="create-btn-modal"
            >
              Create
            </Button>
          </Form>
        </Modal>
        {/* LOCATION MODAL */}
        <Modal
          open={openLocationPicker}
          onCancel={handleLocationPickerCancel}
          centered
          footer={null}
          title="Pick location"
        >
          <Form form={locationForm}>
            <SearchComponent
              name="Location"
              debounceTime={DEBOUNCE_TIME}
              getData={(value) => {
                fetchAddresses(value);
              }}
              style={{
                height: '45px',
                borderRadius: '10px'
              }}
            />

            {loadingSearchResults ? (
              <div className="empty-state">
                <LoaderComponent />
              </div>
            ) : predictions.length === 0 ? (
              <Empty className="empty-state" />
            ) : (
              <div className="fixed-height">
                {predictions.map((prediction, index) => {
                  const predictionId = `prediction-${index}`;
                  return (
                    <div
                      className="location-container"
                      key={predictionId}
                      onClick={() => {
                        const { place_id: placeId } = prediction;
                        selectAddress(placeId);
                      }}
                    >
                      {prediction.description}
                    </div>
                  );
                })}
              </div>
            )}
          </Form>
        </Modal>
      </Header>
      <div className="main-content customers-content">
        <div className="customers-table">
          {loading ? (
            <LoaderComponent />
          ) : (
            <CommonTable
              columns={columns}
              rowKey={(record) => record.id}
              loadingData={loadingData}
              paginationConfig={paginationProp}
              data={doctors}
              onChange={(onChange) => {
                setSkipVal((onChange.current - 1) * limit);
                setLoadingData(true);
                fetchDoctorList({
                  currentQuery: query,
                  currentSkip: (onChange.current - 1) * limit
                });
              }}
            />
          )}
        </div>
        {/* DISABLE MODAL */}
        <Modal
          title={
            <div>
              <Space className="warning-container">
                <WarningFilled />
                Disable Customer
              </Space>
            </div>
          }
          centered
          closeIcon={<CloseCircleOutlined className="close-btn" />}
          open={openDisableUser}
          okButtonProps={{ type: 'primary', danger: 'danger' }}
          cancelButtonProps={{ className: 'no-display' }}
          okText="Disable customer"
          onOk={() => {
            toggleEnabled(currentSelectedIndex);
            setOpenDisableUser(false);
          }}
          onCancel={() => setOpenDisableUser(false)}
        >
          <Divider />
          <p>Are you sure you want to disable this customer?</p>
          <Divider />
        </Modal>
      </div>
    </div>
  );
}
export default AllDoctors;
