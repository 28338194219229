/* eslint-disable no-console */
import {
  DeleteOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
  StarFilled,
  StarOutlined
} from '@ant-design/icons';
import {
  Avatar,
  Button,
  Col,
  Divider,
  Empty,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Space,
  Tooltip,
  Typography,
  Upload,
  message
} from 'antd';
import { Header } from 'antd/es/layout/layout';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as UploadImage } from '../../../assets/upload.svg';
import {
  getFileSignedUrl,
  postUploadIcon
} from '../../../common/common-repository';
import CommonTable from '../../../common/components/CommonTable';
import LoaderComponent from '../../../common/components/LoaderComponent';
import SearchComponent from '../../../common/components/SearchDebounce';
import {
  DEBOUNCE_TIME,
  GLOBAL_PAGINATION_LIMIT
} from '../../../common/constants';
import {
  beforeUpload,
  clearSearchField,
  formValidatorRules
} from '../../../common/utils';
import { getLiveTestsAndPackages } from '../../tests/test-repository';
import '../risk-index.less';
import {
  createNewDisease,
  getAllDiseases,
  removeDisease,
  toggleFeatureDisease,
  updateCurrentDisease,
  updateDiseaseLinkedTest
} from '../risk-repository';

function Diseases() {
  const { required, number } = formValidatorRules;
  const [form] = Form.useForm();
  const limit = GLOBAL_PAGINATION_LIMIT;
  const { pageNo } = useParams();
  const currentPage = parseInt(pageNo, 10) || 1;
  const navigate = useNavigate();

  // STATES
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [diseasesList, setDiseasesList] = useState([]);
  const [query, setQuery] = useState('');
  const [paginationProp, setPaginationProp] = useState();
  const [isSavingData, setIsSavingData] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [currentGlobalSkip, setCurrentGlobalSkip] = useState(0);
  const [uploadedImage, setUploadedImage] = useState();
  const [uploadingImage, setUploadingImage] = useState(false);
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState();
  const [selectedId, setSelectedId] = useState('');
  const [diseaseName, setDiseaseName] = useState('');

  // ADD-REMOVE TESTS STATES
  const [addTestModal, setAddTestModal] = useState(false);
  const [showingTestResults, setShowingTestResults] = useState(false);
  const [selectedTests, setSelectedTests] = useState([]);
  const [fetchedTests, setFetchedTests] = useState([]);
  const [isFetchingTests, setIsFetchingTests] = useState(false);
  const [diseaseId, setDiseasesId] = useState('');
  const [offerName, setOfferName] = useState('');

  const getAllDiseasesList = async ({ currentSkip, currentQuery }) => {
    try {
      setCurrentGlobalSkip(currentSkip);
      const response = await getAllDiseases({
        skip: currentSkip,
        limit: limit,
        query: currentQuery
      });
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setDiseasesList(response.diseases.slice());
      setLoading(false);
      setLoadingData(false);
    } catch (error) {
      setLoading(false);
      setLoadingData(false);
    }
  };

  useEffect(() => {
    getAllDiseasesList({
      currentQuery: query,
      currentSkip: (currentPage - 1) * limit
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = (page) => {
    // Use the navigate function to update the URL
    navigate(`/diseases/${page}`);
  };

  // MODAL
  const handleCancel = () => {
    form?.resetFields();
    setUploadedImage(undefined);
    setSelectedId('');
    setDiseaseName('');
    setIsUpdateModal(false);
    setIsModalOpen(false);
  };

  // UPDATE MODAL
  const getDiseaseToUpdate = (record, value) => {
    setLoadingData(true);
    setTimeout(() => {
      setDiseaseName(value);
      form.setFieldsValue({
        diseaseTitle: record.name,
        noOfAssessment: record.noOfAssessment
      });
      setIsModalOpen(true);
      setSelectedId(record.id);
      setLoadingData(false);
    }, 500);
  };

  // TOGGLE FEATURE
  const toggleFeatured = (index) => {
    diseasesList[index] = {
      ...diseasesList[index],
      isFeatured: !diseasesList[index].isFeatured
    };
    toggleFeatureDisease(diseasesList[index].id);
    setDiseasesList(diseasesList.slice());
  };

  const deleteDisease = async (diseasesId, index) => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Deleting banner'
    });
    const diseaseObj = diseasesList.find((data) => data.id === diseasesId);
    if (diseaseObj?.isFeatured) {
      message.warning('Current disease is featured');
      message.destroy(key);
      return;
    }
    await removeDisease(diseasesId);
    const updatedDIseasesList = diseasesList.slice();
    updatedDIseasesList.splice(index, 1);
    message.open({
      key,
      type: 'success',
      content: 'Deleted'
    });
    message.destroy(key);
    setDiseasesList(updatedDIseasesList);
  };

  // TESTS LINK

  const fetchLiveTests = async (currentSearch) => {
    if (currentSearch === '') {
      setShowingTestResults(false);
      return;
    }
    setShowingTestResults(true);
    setIsFetchingTests(true);
    try {
      const response = await getLiveTestsAndPackages({
        skip: 0,
        limit: 3,
        query: currentSearch
      });
      const { tests } = response;
      const currentFetchedTests = [];
      for (let i = 0; i < tests.length; i += 1) {
        const test = tests[i];
        const { id } = test;
        let isAlreadySelected = false;
        for (let j = 0; j < selectedTests.length; j += 1) {
          if (selectedTests[j].id === id) {
            isAlreadySelected = true;
            break;
          }
        }
        currentFetchedTests.push({
          isSelected: isAlreadySelected,
          test
        });
      }
      setFetchedTests(currentFetchedTests);
    } catch (error) {
      //
    }
    setIsFetchingTests(false);
  };

  const getTestsBelongsToDisease = async (value, record) => {
    setLoadingData(true);
    const { linkedTests } = record;
    setSelectedTests(linkedTests);
    setTimeout(() => {
      setLoadingData(false);
      setAddTestModal(true);
    }, 500);
  };

  const handleCancelADdTestsModal = () => {
    clearSearchField('Tests');
    setSelectedTests([]);
    setDiseasesId('');
    setOfferName('');
    setAddTestModal(false);
  };

  const uploadSelectedTests = async () => {
    setIsSavingData(true);
    const testsId = [];
    for (let i = 0; i < selectedTests.length; i += 1) {
      testsId.push(selectedTests[i].id);
    }
    const data = {
      diseasesId: diseaseId,
      tests: testsId
    };
    await updateDiseaseLinkedTest(data);
    getAllDiseasesList({
      currentQuery: query
    });
    setIsSavingData(false);

    handleCancelADdTestsModal();
  };

  const columns = [
    {
      title: '',
      dataIndex: 'isFeatured',

      align: 'center',
      key: '1',
      render: (value, record, index) => {
        return value ? (
          <StarFilled
            className="filled-star"
            onClick={() => {
              toggleFeatured(index);
            }}
          />
        ) : (
          <StarOutlined
            className="outlined-star"
            onClick={() => {
              toggleFeatured(index);
            }}
          />
        );
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: '2',
      render: (value, record, index) => {
        if (value?.length < 20) {
          return (
            <Typography.Text
              className="clickable-title"
              onClick={() => {
                form?.resetFields();
                setUploadedImage(record.thumbnail);
                setIsUpdateModal(true);
                setCurrentSelectedIndex(index);
                setTimeout(() => {
                  getDiseaseToUpdate(record, value);
                }, 200);
              }}
            >
              {value}
            </Typography.Text>
          );
        }
        return (
          <Typography.Text
            className="clickable-title"
            onClick={() => {
              form?.resetFields();
              setUploadedImage(record.thumbnail);
              setIsUpdateModal(true);
              setCurrentSelectedIndex(index);
              setTimeout(() => {
                getDiseaseToUpdate(record, value);
              }, 200);
            }}
          >
            <Tooltip title={value} color="#232e78">
              {value?.slice(0, 20)}...
            </Tooltip>
          </Typography.Text>
        );
      }
    },
    {
      title: 'Thumbnail',
      dataIndex: 'thumbnail',
      key: '3',
      render: (value) => {
        return <Avatar src={value} alt={value} />;
      }
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: '5',
      align: 'center',
      render: (value, record, index) => {
        return (
          <div className="btn-space">
            <PlusCircleOutlined
              size={40}
              onClick={() => {
                setDiseasesId(value);
                setOfferName(record.title);
                setTimeout(() => {
                  getTestsBelongsToDisease(value, record, index);
                }, 200);
              }}
            />
            <Popconfirm
              title="Delete"
              description="Are you sure to delete this banner?"
              onConfirm={() => {
                deleteDisease(value, index);
              }}
              okText="Yes"
              cancelText="No"
            >
              {/* <Button className="delete-btn-banner"> */}
              <DeleteOutlined className="delete-btn " size={40} />
              {/* </Button> */}
            </Popconfirm>
          </div>
        );
      }
    },
    {
      title: 'Questions',
      dataIndex: 'id',
      key: '6',
      align: 'center',
      render: (value) => {
        const url = `/diseases/${currentPage}/${value}`;
        return (
          <Link to={url}>
            <AiOutlineArrowRight
              size={20}
              style={{
                cursor: 'pointer'
              }}
            />
          </Link>
        );
      }
    }
  ];

  const createForm = async (formValues) => {
    if (!uploadedImage) {
      message.error('Thumbnail is not uploaded');
      return;
    }
    const payload = {
      name: formValues.diseaseTitle,
      noOfAssessment: parseInt(formValues.noOfAssessment, 10),
      thumbnail: uploadedImage
    };
    setIsSavingData(true);
    const createdDisease = await createNewDisease(payload);
    if (
      diseasesList.length === 0 ||
      diseasesList.length < limit ||
      diseasesList[diseasesList.length - 1].serialNumber + 1 ===
        createdDisease.serialNumber
    ) {
      const updatedDisease = diseasesList.slice();
      updatedDisease.push(createdDisease);
      setDiseasesList(updatedDisease.slice());
    }

    handleCancel();
    setIsSavingData(false);
    getAllDiseasesList({
      currentQuery: query,
      currentSkip: currentGlobalSkip
    });
  };

  const updateForm = async (formValues) => {
    if (!uploadedImage) {
      message.error('Thumbnail is not uploaded');
      return;
    }
    const payload = {
      name: formValues.diseaseTitle,
      noOfAssessment: parseInt(formValues.noOfAssessment, 10),
      thumbnail: uploadedImage
    };
    setIsSavingData(true);
    const updatedDisease = await updateCurrentDisease(payload, selectedId);
    const diseaseNewList = diseasesList.slice();
    diseaseNewList[currentSelectedIndex] = updatedDisease;
    setDiseasesList(diseaseNewList);
    handleCancel();
    setIsSavingData(false);
  };

  const onIconUpload = async (selectedFile) => {
    setUploadingImage(true);
    const fileName = selectedFile.file.name;
    const response = await postUploadIcon(fileName);
    const { signedUrl, getUrl } = response;
    const config = {
      method: 'put',
      url: signedUrl,
      headers: {
        'Content-Type': 'image/svg+xml'
      },
      data: selectedFile.file
    };
    await axios(config);
    const url = await getFileSignedUrl(getUrl);
    setUploadedImage(url);
    setUploadingImage(false);
  };

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Typography.Text className="main-header-title">
              Diseases
            </Typography.Text>
            <SearchComponent
              name="Diseases"
              debounceTime={DEBOUNCE_TIME}
              getData={(value) => {
                setQuery(value);
                setLoadingData(true);
                getAllDiseasesList({
                  currentQuery: value,
                  currentSkip: 0
                });
              }}
            />
          </Space>
          <Button onClick={() => setIsModalOpen(true)} className="create-btn">
            + Create
          </Button>

          {/* CREATE MODAL */}
          <Modal
            open={isModalOpen}
            onCancel={handleCancel}
            className="offer-modal"
            title={isUpdateModal ? diseaseName : 'Create Disease'}
            centered
          >
            <Divider />
            <div className="form-contents">
              <Form
                form={form}
                onFinish={isUpdateModal ? updateForm : createForm}
                className="equipment-form"
                layout="vertical"
              >
                <Row className="upper-contents">
                  <Col className="upload-thumbnail">
                    <Upload
                      name="upload-icon"
                      onChange={onIconUpload}
                      accept=".svg"
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                    >
                      {uploadedImage && !uploadingImage && (
                        <Avatar src={uploadedImage} alt="Selected image" />
                      )}
                      {!uploadedImage && !uploadingImage && (
                        <UploadImage className="category-image" />
                      )}
                      {uploadingImage && <LoadingOutlined />}
                      <p>Upload disease thumbnail</p>
                    </Upload>
                  </Col>
                  <Col>
                    <Form.Item
                      rules={[required]}
                      name="diseaseTitle"
                      className="input-title"
                      label="Disease Name"
                    >
                      <Input type="text" placeholder="Disease name" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="upper-contents">
                  <Col>
                    <Form.Item
                      rules={[number]}
                      name="noOfAssessment"
                      label="No. of Assessment"
                    >
                      <Input type="text" placeholder="Enter assessment no" />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
                <div className="timely-btn-space ">
                  <Button loading={isSavingData} htmlType="submit">
                    {isUpdateModal ? 'Update' : 'Add'}
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
          {/* TEST LINKING MODAL */}
          <Modal
            title={offerName || 'UPDATE TESTS'}
            centered
            className="create-test-modal"
            open={addTestModal}
            onCancel={handleCancelADdTestsModal}
          >
            <Divider />
            <div className="tests-modal-search">
              <SearchComponent
                name="Tests"
                debounceTime={DEBOUNCE_TIME}
                getData={fetchLiveTests}
              />

              {!isFetchingTests && showingTestResults && (
                <div className="search-result" id="test-searcher">
                  {fetchedTests.length <= 0 ? (
                    <Empty />
                  ) : (
                    fetchedTests.map((currentTest) => {
                      const { isSelected, test } = currentTest;
                      return (
                        <div className="tests-list" key={test.id}>
                          <Typography.Text>{test.title}</Typography.Text>
                          {isSelected ? (
                            <Button
                              type="text"
                              onClick={() => {
                                const tests = selectedTests.slice();
                                for (let i = 0; i < tests.length; i += 1) {
                                  if (tests[i].id === test.id) {
                                    tests.splice(i, 1);
                                  }
                                }
                                const tempFetchedTests = fetchedTests.slice();
                                for (
                                  let i = 0;
                                  i < tempFetchedTests.length;
                                  i += 1
                                ) {
                                  if (tempFetchedTests[i].test.id === test.id) {
                                    tempFetchedTests[i] = {
                                      ...tempFetchedTests[i],
                                      isSelected: false
                                    };
                                    break;
                                  }
                                }
                                setSelectedTests(tests);
                                setFetchedTests(tempFetchedTests);
                              }}
                            >
                              Remove
                            </Button>
                          ) : (
                            <Button
                              onClick={() => {
                                const tests = selectedTests.slice();
                                tests.push(test);
                                const tempFetchedTests = fetchedTests.slice();
                                for (
                                  let i = 0;
                                  i < tempFetchedTests.length;
                                  i += 1
                                ) {
                                  if (tempFetchedTests[i].test.id === test.id) {
                                    tempFetchedTests[i] = {
                                      ...tempFetchedTests[i],
                                      isSelected: true
                                    };
                                    break;
                                  }
                                }
                                setSelectedTests(tests);
                                setFetchedTests(tempFetchedTests);
                              }}
                              type="default"
                            >
                              Add
                            </Button>
                          )}
                        </div>
                      );
                    })
                  )}
                </div>
              )}
            </div>
            <div className="selected-test-container category-container">
              {selectedTests?.length <= 0 ? (
                <Empty />
              ) : (
                selectedTests.map((currentTest) => {
                  const { id, title } = currentTest;
                  return (
                    <div className="tests-list" key={id}>
                      <Typography.Text>{title}</Typography.Text>
                      <Button
                        type="text"
                        onClick={() => {
                          const tests = selectedTests.slice();
                          for (let i = 0; i < tests.length; i += 1) {
                            if (tests[i].id === id) {
                              tests.splice(i, 1);
                            }
                          }
                          setSelectedTests(tests);
                        }}
                      >
                        Remove
                      </Button>
                    </div>
                  );
                })
              )}
            </div>
            <Divider />
            <Button
              loading={isSavingData}
              className="create-btn offer-save-btn"
              onClick={uploadSelectedTests}
            >
              SAVE
            </Button>
          </Modal>
        </div>
      </Header>
      <div className="main-content test-categories-content">
        {loading ? (
          <LoaderComponent />
        ) : (
          <CommonTable
            columns={columns}
            rowKey={(record) => record.id}
            loadingData={loadingData}
            paginationConfig={paginationProp}
            data={diseasesList}
            onChange={(onChange) => {
              // eslint-disable-next-line no-console
              setLoadingData(true);
              getAllDiseasesList({
                currentQuery: query,
                currentSkip: (onChange.current - 1) * limit
              });
              handlePageChange(onChange.current);
            }}
          />
        )}
      </div>
    </div>
  );
}

export default Diseases;
