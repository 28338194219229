import client from '../../apollo';
import {
  APPROVE_REPORT_AS_ADMIN,
  APPROVE_WHOLE_REPORT_AS_ADMIN,
  CANCEL_ORDER,
  CANCEL_ORDER_FORCEFULLY,
  CHANGE_ORDER_ADDRESS,
  FORCED_REPLACE_SAMPLE_COLLECTOR,
  LINK_DOCTOR_TO_ORDER,
  MARK_REPORT_AS_DELIVERED,
  REJECT_REPORT_AS_ADMIN,
  REJECT_WHOLE_REPORT_AS_ADMIN,
  REPLACE_SAMPLE_COLLECTOR,
  RESCHEDULE_SAMPLE_COLLECTION,
  SAMPLES_SUBMIT_TO,
  UPDATE_ORDER_AS_ADMIN,
  UPDATE_PATIENT_IN_ORDER,
  UPLOAD_REPORT_AS_ADMIN,
  UPLOAD_WHOLE_REPORT_AS_ADMIN
} from './graphql/mutation';
import {
  GENERATE_INVOICE,
  GET_ADMIN_REPORTS,
  GET_ADMIN_REPORT_ORDER,
  GET_AVAILABLE_SAMPLE_COLLECTORS,
  GET_CUSTOMER_ADDRESS_AS_ADMIN,
  GET_CUSTOMER_PATIENTS,
  GET_HISTORY_ADMIN_REPORTS,
  GET_ORDERS_BY_ID_AS_ADMIN,
  GET_ORDER_LOGS,
  GET_ORDER_TRANSACHTIONS,
  GET_SLOTS_BY_DAY
} from './graphql/query';

export const getActiveOrdersAsAdmin = async (filters) => {
  const { skip, limit, query, startDate, endDate } = filters;
  try {
    const response = await client.query({
      query: GET_ADMIN_REPORTS,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query,
          startDate,
          endDate
        }
      }
    });
    return response?.data?.getActiveOrdersAsAdmin;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const getOrderByIdAsAdmin = async (orderId) => {
  try {
    const response = await client.query({
      query: GET_ADMIN_REPORT_ORDER,
      fetchPolicy: 'network-only',
      variables: {
        orderId
      }
    });
    return response?.data?.getOrderByIdAsAdmin;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const getAvailableSampleCollectors = async (date, time) => {
  try {
    const response = await client.query({
      query: GET_AVAILABLE_SAMPLE_COLLECTORS,
      fetchPolicy: 'network-only',
      variables: {
        date,
        time
      }
    });
    return response?.data?.getAvailableSampleCollectorsAtTime?.sampleCollectors;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const getSlotsByDay = async (filter) => {
  const { day } = filter;
  try {
    const response = await client.query({
      query: GET_SLOTS_BY_DAY,
      fetchPolicy: 'network-only',
      variables: {
        filter: {
          day
        }
      }
    });
    return response?.data?.getSlotsByDay;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const getOrderLogs = async (filters, id) => {
  const { skip, limit } = filters;
  try {
    const response = await client.query({
      query: GET_ORDER_LOGS,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit
        },
        id
      }
    });
    return response?.data?.getOrderLogs;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const uploadReportAsAdmin = async (data) => {
  try {
    await client.mutate({
      mutation: UPLOAD_REPORT_AS_ADMIN,
      fetchPolicy: 'network-only',
      variables: {
        ...data
      }
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const rejectReportAsAdmin = async (data) => {
  try {
    await client.mutate({
      mutation: REJECT_REPORT_AS_ADMIN,
      fetchPolicy: 'network-only',
      variables: {
        ...data
      }
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const rejectWholeReportAsAdmin = async (orderId, rejectionReason) => {
  try {
    await client.mutate({
      mutation: REJECT_WHOLE_REPORT_AS_ADMIN,
      fetchPolicy: 'network-only',
      variables: { orderId, rejectionReason }
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const approveReportAsAdmin = async (data) => {
  try {
    await client.mutate({
      mutation: APPROVE_REPORT_AS_ADMIN,
      fetchPolicy: 'network-only',
      variables: {
        ...data
      }
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const approveWholeReportAsAdmin = async (orderId) => {
  try {
    await client.mutate({
      mutation: APPROVE_WHOLE_REPORT_AS_ADMIN,
      fetchPolicy: 'network-only',
      variables: { orderId }
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const markReportAsDelivered = async (data) => {
  try {
    await client.mutate({
      mutation: MARK_REPORT_AS_DELIVERED,
      fetchPolicy: 'network-only',
      variables: {
        ...data
      }
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const replaceSampleCollector = async (data) => {
  try {
    await client.mutate({
      mutation: REPLACE_SAMPLE_COLLECTOR,
      fetchPolicy: 'network-only',
      variables: {
        ...data
      }
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const cancelOrderAsAdmin = async (orderId) => {
  try {
    const response = await client.mutate({
      mutation: CANCEL_ORDER,
      fetchPolicy: 'network-only',
      variables: {
        orderId
      }
    });
    return response?.data?.cancelOrderAsAdmin;
  } catch (error) {
    return false;
  }
};

export const cancelOrderForcefullyAsAdmin = async (orderId) => {
  try {
    const response = await client.mutate({
      mutation: CANCEL_ORDER_FORCEFULLY,
      fetchPolicy: 'network-only',
      variables: {
        orderId
      }
    });
    return response?.data?.cancelOrderWithoutRefund;
  } catch (error) {
    return false;
  }
};

export const rescheduleSampleCollector = async (
  orderId,
  slotId,
  selectedDate
) => {
  try {
    await client.mutate({
      mutation: RESCHEDULE_SAMPLE_COLLECTION,
      fetchPolicy: 'network-only',
      variables: {
        orderId,
        slotId,
        selectedDate
      }
    });
    return true;
  } catch (error) {
    return false;
  }
};
export const getOrderHistory = async (filters) => {
  const { skip, limit, query, startDate, endDate } = filters;
  try {
    const response = await client.query({
      query: GET_HISTORY_ADMIN_REPORTS,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query,
          startDate,
          endDate
        }
      }
    });
    return response?.data?.getOrderHistory;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const getOrderTransactions = async (orderId) => {
  try {
    const response = await client.query({
      query: GET_ORDER_TRANSACHTIONS,
      fetchPolicy: 'network-only',
      variables: {
        orderId
      }
    });
    return response?.data?.getOrderByIdAsAdmin;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const submitSampleTo = async (orderId, labId) => {
  try {
    const response = await client.mutate({
      mutation: SAMPLES_SUBMIT_TO,
      fetchPolicy: 'network-only',
      variables: { orderId, labId }
    });
    return response;
  } catch (error) {
    return false;
  }
};

export const uploadWholeReportAsAdmin = async (data) => {
  try {
    await client.mutate({
      mutation: UPLOAD_WHOLE_REPORT_AS_ADMIN,
      fetchPolicy: 'network-only',
      variables: {
        ...data
      }
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const getInvoiceByOrderId = async (orderId) => {
  try {
    const response = await client.query({
      query: GENERATE_INVOICE,
      fetchPolicy: 'network-only',
      variables: {
        orderId
      }
    });
    return response?.data?.getInvoiceByOrderId;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const getCustomerAddressesAsAdmin = async (filters, customerId) => {
  const { skip, limit } = filters;
  try {
    const response = await client.query({
      query: GET_CUSTOMER_ADDRESS_AS_ADMIN,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit
        },
        customerId
      }
    });
    return response?.data?.getCustomerAddressesAsAdmin?.addresses;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const updateOrderAddress = async (orderId, addressId) => {
  try {
    const response = await client.mutate({
      mutation: CHANGE_ORDER_ADDRESS,
      fetchPolicy: 'network-only',
      variables: { orderId, addressId }
    });
    return response;
  } catch (error) {
    return false;
  }
};

export const getCustomerPatients = async (customerId, filters) => {
  try {
    const { skip, limit } = filters;
    const response = await client.mutate({
      mutation: GET_CUSTOMER_PATIENTS,
      fetchPolicy: 'network-only',
      variables: {
        customerId,
        filters: {
          skip,
          limit
        }
      }
    });
    return response?.data?.getPatientsAsAdmin;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const updatePatientInOrder = async (orderId, patientId) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_PATIENT_IN_ORDER,
      fetchPolicy: 'network-only',
      variables: { orderId, patientId }
    });
    return response;
  } catch (error) {
    return false;
  }
};

export const replaceSampleCollectorForce = async (data) => {
  try {
    await client.mutate({
      mutation: FORCED_REPLACE_SAMPLE_COLLECTOR,
      fetchPolicy: 'network-only',
      variables: {
        ...data
      }
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const getOrdersByIdAsAdmin = async (orderIds) => {
  try {
    const response = await client.query({
      query: GET_ORDERS_BY_ID_AS_ADMIN,
      fetchPolicy: 'network-only',
      variables: {
        orderIds
      }
    });
    return response?.data?.getOrdersByIdAsAdmin;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const linkDoctorToOrder = async (orderId, doctorId) => {
  try {
    const response = await client.mutate({
      mutation: LINK_DOCTOR_TO_ORDER,
      fetchPolicy: 'network-only',
      variables: { orderId, doctorId }
    });
    return response;
  } catch (error) {
    return false;
  }
};

export const updateOrderAsAdmin = async (data) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_ORDER_AS_ADMIN,
      fetchPolicy: 'network-only',
      variables: { data }
    });
    return response;
  } catch (error) {
    return false;
  }
};
