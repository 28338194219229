/* eslint-disable no-console */
import client from '../../apollo';
import {
  ADD_DISEASE_QUESTION,
  CREATE_DISEASE_CATEGORY,
  CREATE_NEW_DISEASE,
  DELETE_DISEASE,
  DISEASE_LINKED_TESTS,
  LINK_DOCTOR_TO_CATEGORY,
  REMOVE_QUESTION,
  TOGGLE_ACTION_DISEASE_CATEGORY,
  TOGGLE_FEATURE_DISEASE,
  TOGGLE_FEATURE_DISEASE_CATEGORY,
  UPDATE_CURRENT_DISEASE,
  UPDATE_DISEASE_CATEGORY,
  UPDATE_DISEASE_QUESTION
} from './graphql/mutation';
import {
  GET_ALL_DISEASES,
  GET_ALL_DISEASES_CATEGORIES,
  GET_DISEASE_BY_ID
} from './graphql/query';

export const getAllDiseases = async (filters) => {
  const { skip, limit, query } = filters;
  try {
    const response = await client.query({
      query: GET_ALL_DISEASES,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query
        }
      }
    });
    return response?.data?.getDiseases;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const updateCurrentDisease = async (data, diseasesId) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_CURRENT_DISEASE,
      fetchPolicy: 'network-only',
      variables: { data, diseasesId }
    });

    return response?.data?.updateDiseasesAsAdmin?.diseases;
  } catch (error) {
    return false;
  }
};

export const createNewDisease = async (data) => {
  try {
    const response = await client.mutate({
      mutation: CREATE_NEW_DISEASE,
      fetchPolicy: 'network-only',
      variables: { data }
    });
    return response?.data?.createDiseases?.diseases;
  } catch (error) {
    return false;
  }
};

export const toggleFeatureDisease = async (diseasesId) => {
  try {
    const response = await client.mutate({
      mutation: TOGGLE_FEATURE_DISEASE,
      fetchPolicy: 'network-only',
      variables: {
        diseasesId
      }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

export const removeDisease = async (diseasesId) => {
  try {
    const response = await client.query({
      query: DELETE_DISEASE,
      fetchPolicy: 'network-only',
      variables: {
        diseasesId
      }
    });
    return response?.data;
  } catch (error) {
    return [];
  }
};

export const getAllDiseaseCategories = async (filters) => {
  const { skip, limit, query } = filters;
  try {
    const response = await client.query({
      query: GET_ALL_DISEASES_CATEGORIES,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query
        }
      }
    });
    return response?.data?.getDiseasesCategoriesAsAdmin;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const toggleFeatureDiseaseCategory = async (
  toggleDiseasesCategoryId
) => {
  try {
    const response = await client.mutate({
      mutation: TOGGLE_FEATURE_DISEASE_CATEGORY,
      fetchPolicy: 'network-only',
      variables: {
        toggleDiseasesCategoryId
      }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

export const toggleActionDiseaseCategory = async (categoryId) => {
  try {
    const response = await client.mutate({
      mutation: TOGGLE_ACTION_DISEASE_CATEGORY,
      fetchPolicy: 'network-only',
      variables: {
        categoryId
      }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

export const createNewDiseaseCategory = async (data) => {
  try {
    const response = await client.mutate({
      mutation: CREATE_DISEASE_CATEGORY,
      fetchPolicy: 'network-only',
      variables: { data }
    });
    return response?.data?.createDiseasesCategory?.diseases;
  } catch (error) {
    return false;
  }
};

export const updateDiseaseCategory = async (data, categoryId) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_DISEASE_CATEGORY,
      fetchPolicy: 'network-only',
      variables: { data, categoryId }
    });

    return response?.data?.updateDiseasesCategory?.diseasesCategory;
  } catch (error) {
    return false;
  }
};

export const getDiseaseById = async (diseasesId) => {
  try {
    const response = await client.query({
      query: GET_DISEASE_BY_ID,
      fetchPolicy: 'network-only',
      variables: {
        diseasesId
      }
    });
    return response?.data?.getDiseasesByIdAsAdmin;
  } catch (error) {
    // eslint-disable-next-line no-console
    return {};
  }
};

export const addQuestionToDisease = async (data) => {
  try {
    const response = await client.mutate({
      mutation: ADD_DISEASE_QUESTION,
      fetchPolicy: 'network-only',
      variables: { data }
    });

    return response?.data?.addQuestionToDiseases?.diseases;
  } catch (error) {
    throw new Error();
  }
};

export const updateQuestionToDisease = async (data) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_DISEASE_QUESTION,
      fetchPolicy: 'network-only',
      variables: { data }
    });

    return response?.data?.updateDiseasesQuestion?.diseases;
  } catch (error) {
    throw new Error();
  }
};

export const removeQuestionFromDIsease = async (data) => {
  try {
    const response = await client.mutate({
      mutation: REMOVE_QUESTION,
      fetchPolicy: 'network-only',
      variables: { data }
    });

    return response?.data?.removeQuestionToDiseases?.diseases;
  } catch (error) {
    throw new Error();
  }
};

export const updateDiseaseLinkedTest = async (data) => {
  try {
    const response = await client.mutate({
      mutation: DISEASE_LINKED_TESTS,
      fetchPolicy: 'network-only',
      variables: { data }
    });
    return response;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const linkDoctorToCategory = async (data) => {
  try {
    const response = await client.mutate({
      mutation: LINK_DOCTOR_TO_CATEGORY,
      fetchPolicy: 'network-only',
      variables: data
    });
    return response;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const test = async () => {};
