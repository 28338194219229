import client from '../../apollo';
import {
  ADD_SYSTEM_DOCTOR,
  REFRESH_ALL_DOC_PROFILE,
  REFRESH_DOCTOR_PROFILE_BY_ID,
  TOGGLE_ENABLE_DOCTOR,
  UPDATE_DOCTOR_AS_ADMIN
} from './graphql/mutation';
import {
  DOCTOR_REVENUE_ORDERS,
  GET_ALL_DOCTORS,
  GET_DOCTOR_BY_ID,
  GET_DOCTOR_LINKED_ORDERS,
  GET_PROFILE_URL,
  GET_XL_DATA
} from './graphql/query';

export const getDoctorsList = async (filters) => {
  const { skip, limit, query } = filters;
  try {
    const response = await client.query({
      query: GET_ALL_DOCTORS,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query
        }
      }
    });
    return response?.data?.getAllDoctors;
  } catch (error) {
    return [];
  }
};

export const createSystemDoctor = async (data) => {
  try {
    const response = await client.mutate({
      mutation: ADD_SYSTEM_DOCTOR,
      fetchPolicy: 'network-only',
      variables: { data }
    });
    return response?.data?.addSystemDoctor?.doctor;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const getDoctorDetails = async (doctorId) => {
  try {
    const response = await client.mutate({
      mutation: GET_DOCTOR_BY_ID,
      fetchPolicy: 'network-only',
      variables: { doctorId }
    });
    return response?.data?.getDoctorByIdAsAdmin?.doctor;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const toggleDoctorEnable = async (doctorId) => {
  try {
    const response = await client.mutate({
      mutation: TOGGLE_ENABLE_DOCTOR,
      fetchPolicy: 'network-only',
      variables: {
        doctorId
      }
    });

    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const updateDoctorAsAdmin = async (doctorId, data) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_DOCTOR_AS_ADMIN,
      fetchPolicy: 'network-only',
      variables: { doctorId, data }
    });
    return response?.data?.updateDoctor?.doctor;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const getLinkedOrderOfDoctor = async (doctorId, filters) => {
  try {
    const response = await client.query({
      query: GET_DOCTOR_LINKED_ORDERS,
      fetchPolicy: 'network-only',
      variables: {
        doctorId,
        filters
      }
    });
    return response?.data?.getOrdersByDoctorIdAsAdmin;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const getDocRevenueOrders = async (doctorId, filters) => {
  try {
    const response = await client.query({
      query: DOCTOR_REVENUE_ORDERS,
      fetchPolicy: 'network-only',
      variables: {
        doctorId,
        filters
      }
    });
    return response?.data?.getCompletedOrdersByDoctorIdAsAdmin;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const getDoctorRevenueData = async (filters) => {
  try {
    const response = await client.query({
      query: GET_XL_DATA,
      fetchPolicy: 'network-only',
      variables: { filters }
    });
    return response?.data?.getExcelForDoctor;
  } catch (error) {
    return [];
  }
};

export const refreshDoctorByID = async (doctorId) => {
  try {
    const response = await client.mutate({
      mutation: REFRESH_DOCTOR_PROFILE_BY_ID,
      fetchPolicy: 'network-only',
      variables: {
        doctorId
      }
    });

    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const refreshAllDoctors = async () => {
  try {
    const response = await client.mutate({
      mutation: REFRESH_ALL_DOC_PROFILE,
      fetchPolicy: 'network-only'
    });

    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const getDoctorProfileUrl = async (doctorId) => {
  try {
    const response = await client.query({
      query: GET_PROFILE_URL,
      fetchPolicy: 'network-only',
      variables: { doctorId }
    });
    return response?.data?.getDoctorDigitalProfileByIdAsAdmin;
  } catch (error) {
    return false;
  }
};

export const test = async () => {};
