/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-console */
/* eslint-disable react/no-danger */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import {
  CheckCircleTwoTone,
  CheckOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { getDatabase, off, onValue, ref } from 'firebase/database';

import {
  Button,
  Col,
  Collapse,
  Divider,
  Empty,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Rate,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
  Typography,
  Upload
} from 'antd';
import { Header } from 'antd/es/layout/layout';
import axios from 'axios';
import moment from 'moment/moment';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import dayjs from 'dayjs';
import { useReactToPrint } from 'react-to-print';
import { ReactComponent as Back } from '../../../../assets/icons/back.svg';
import { ReactComponent as Backward } from '../../../../assets/icons/backward.svg';
import { ReactComponent as Forward } from '../../../../assets/icons/forward.svg';
import { ReactComponent as TickCircle } from '../../../../assets/icons/tick-circle.svg';
import { ReactComponent as Location } from '../../../../assets/location.svg';
import { ReactComponent as Profile } from '../../../../assets/profile.svg';
import { ReactComponent as AfternoonImage } from '../../../../assets/slots/afternoon.svg';
import { ReactComponent as EveningImage } from '../../../../assets/slots/evening.svg';
import { ReactComponent as MorningImage } from '../../../../assets/slots/morning.svg';
import EndImg from '../../../../assets/timeline/endNon.svg';
import RegularImg from '../../../../assets/timeline/regularNon.svg';
import StartImg from '../../../../assets/timeline/start.svg';
import {
  getFileSignedUrl,
  postUploadReport
} from '../../../../common/common-repository';

import {
  addDays,
  getDateIntoDDMMYYYY,
  getDifferenceInDays,
  getOrderStatusTags,
  getPayVia,
  getTag
} from '../../../../common/components/common-components';
import LoaderComponent from '../../../../common/components/LoaderComponent';
import SearchComponent from '../../../../common/components/SearchDebounce';
import { DEBOUNCE_TIME, ROUTES } from '../../../../common/constants';
import firebaseConfig from '../../../../common/firebase-config';
import {
  clearSearchField,
  formatDate,
  formValidatorRules,
  monthNames
} from '../../../../common/utils';
import { getRemoteConfigValue } from '../../../configurations/configurations-repository';
import { getSampleCollectors } from '../../../tests/components/equipments/equipments-repository';
import { getLiveTestsAndPackages } from '../../../tests/test-repository';
import {
  approveReportAsAdmin,
  approveWholeReportAsAdmin,
  cancelOrderAsAdmin,
  cancelOrderForcefullyAsAdmin,
  getAvailableSampleCollectors,
  getCustomerAddressesAsAdmin,
  getCustomerPatients,
  getInvoiceByOrderId,
  getOrderByIdAsAdmin,
  getOrderLogs,
  getSlotsByDay,
  markReportAsDelivered,
  rejectReportAsAdmin,
  rejectWholeReportAsAdmin,
  replaceSampleCollector,
  replaceSampleCollectorForce,
  rescheduleSampleCollector,
  updateOrderAddress,
  updateOrderAsAdmin,
  updatePatientInOrder,
  uploadReportAsAdmin,
  uploadWholeReportAsAdmin
} from '../../reports-repository';
import './orderReport.less';

const { required } = formValidatorRules;

function OrderReport() {
  const delayedMinutes = process.env.REACT_APP_TIMER_DELAY_IN_SLOTS_MINS;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const componentRef = useRef();
  // STATES
  const [isLoading, setIsLoading] = useState(true);
  const [isHoliday, setIsHoliday] = useState(false);
  const [disablePatientBtn, setDisablePatientBtn] = useState(true);
  const [disableAddressBtn, setDisableAddressBtn] = useState(true);
  const [isLoadingRescheduling, setIsLoadingRescheduling] = useState(false);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [isLogModalOpen, setIsLogModalOpen] = useState(false);
  const [isRescheduleModal, setIsRescheduleModal] = useState(false);
  const [order, setOrder] = useState();
  const [tests, setTests] = useState();
  const [availableSampleCollectors, setAvailableSampleCollectors] = useState(
    []
  );
  const [maximumBookingPerSlot, setMaximumBookingPerSlot] = useState(0);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [slotForReschedule, setSlotForReschedule] = useState();
  const [refetchingAvailableSlots, setRefetchingAvailableSlots] =
    useState(false);
  const [currentAssignedCollector, setCurrentAssignedCollector] = useState();
  const [currentDate, setCurrentDate] = useState();
  const [clickSaveToUpdate, setClickSaveToUpdate] = useState(false);
  const [isDeliveringReport, setIsDeliveringReport] = useState(false);
  const [allLogs, setAllLogs] = useState([]);
  const [openRejectionModal, setOpenRejectionModal] = useState(false);
  const [openRejectionWholeModal, setOpenRejectionWholeModal] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [currentTestId, setCurrentTestId] = useState();
  const [transactionId, setTransactionId] = useState();
  const [address, setAddress] = useState([]);
  const [patients, setPatients] = useState([]);
  const [addressId, setAddressId] = useState('');
  const [addressLoading, setAddressLoading] = useState(false);
  const [patientUpdateLoading, setPatientUpdateLoading] = useState(false);
  const [patientId, setPatientId] = useState('');
  const [patientError, setPatientError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [availableCollectors, setAvailableCollectors] = useState([]);
  const [currentSlot, setCurrentSlot] = useState({
    date: '',
    time: ''
  });
  const [logs, setLogs] = useState();
  const [currentDBRef, setCurrentDBRef] = useState();
  const [colorChange, setColorChange] = useState({
    samplesCollected: false,
    sendToLab: false,
    reportUploaded: false,
    reportPublished: false,
    delivered: false
  });
  const [formFlag, setFormFlag] = useState('WHOLE_REPORT');
  const [forcedReplace, setForcedReplace] = useState(false);
  const [allSampleCollectors, setAllSampleCollectors] = useState([]);
  const [forcedSampleCollectorId, setForcedSampleCollectorId] = useState(null);
  const onPDFUpload = async (selectedFile) => {
    const fileName = selectedFile.file.name;
    const response = await postUploadReport(fileName);
    const { signedUrl, getUrl } = response;
    const config = {
      method: 'put',
      url: signedUrl,
      headers: {
        'Content-Type': 'application/pdf'
      },
      data: selectedFile.file
    };
    await axios(config);
    const url = await getFileSignedUrl(getUrl);
    return url;
  };
  const beforeUpload = (file) => {
    const isPDF = file.type === 'application/pdf';
    const isLT10MB = file.size / 1024 / 1024 <= 10;
    if (!isLT10MB) {
      message.error('PDF must smaller than 10MB!');
      return !isLT10MB && Upload.LIST_IGNORE;
    }
    if (!isPDF) {
      message.error(`${file?.name} is not a svg file`);
    }
    return false;
  };

  // ADD-REMOVE TESTS STATES
  const [addTestModal, setAddTestModal] = useState(false);
  const [showingTestResults, setShowingTestResults] = useState(false);
  const [selectedTests, setSelectedTests] = useState([]);
  const [fetchedTests, setFetchedTests] = useState([]);
  const [isFetchingTests, setIsFetchingTests] = useState(false);
  const [isSavingData, setIsSavingData] = useState(false);

  // const getMaximumPerson = async () => {
  //   const remoteConfig = getRemoteConfig(firebaseConfig);
  //   await fetchAndActivate(remoteConfig);
  //   const maxSlot = getNumber(remoteConfig, 'timeslot_booking_max_limit');
  //   setMaximumBookingPerSlot(maxSlot);
  // };

  // BELOW FUNC CALL FROM BACKEND API NOT REAL TIME (2nd SOL)
  const getMaximumPerson = async () => {
    try {
      const response = await getRemoteConfigValue();
      setMaximumBookingPerSlot(response?.data[0]?.value);
    } catch (error) {
      //
    }
  };

  const arrangeAvailableSlots = (daySlots, liveData, date) => {
    if (liveData && liveData.is_holiday) {
      setIsHoliday(true);
      setRefetchingAvailableSlots(false);
      setAvailableSlots([]);
      return;
    }
    setIsHoliday(false);
    const tempAvailableSlots = [];
    const tempCurrentTime = new Date();
    tempCurrentTime.setMinutes(
      tempCurrentTime.getMinutes() + parseInt(delayedMinutes, 10)
    );
    const differ =
      tempCurrentTime && date ? getDifferenceInDays(tempCurrentTime, date) : -1;
    for (let i = 0; i < daySlots.length; i += 1) {
      const daySlot = daySlots[i];
      const { id = '' } = daySlot;
      const currentCapacity = liveData.slots[id] || 0;
      let isPastTime = false;
      if (differ === 0) {
        const newTimeWhenDayStarted = new Date(
          tempCurrentTime.getFullYear(),
          tempCurrentTime.getMonth(),
          tempCurrentTime.getDate()
        );
        const diffMs = tempCurrentTime - newTimeWhenDayStarted; // milliseconds between now & Christmas
        const inSec = diffMs / 1000;
        const inMin = inSec / 60;
        isPastTime = inMin > daySlot.startTime;
      }
      const tempAvailableSlot = {
        available:
          currentCapacity <
            (daySlot.maxBookingLimit || maximumBookingPerSlot) && !isPastTime, // ADD CONDITION NOT HAVE TIME BEFORE TIME // DISABLE IF ID AND DAY SAME //
        slot: daySlot,
        selected: false
      };
      tempAvailableSlots.push(tempAvailableSlot);
    }
    setRefetchingAvailableSlots(false);
    setSlotForReschedule(undefined);
    setAvailableSlots(tempAvailableSlots);
  };
  const monitorSlots = async (date) => {
    if (currentDBRef) {
      off(currentDBRef);
      setCurrentDBRef(undefined);
    }
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Fetching slots...'
    });
    const days = [
      'SUNDAY',
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ];

    const response = await getSlotsByDay({
      day: days[date.getDay()]
    });

    const allSLotsArr = [];
    const { morning, afternoon, evening } = response;

    morning.forEach((slot) => {
      allSLotsArr.push({
        ...slot,
        isMorning: true,
        isAfternoon: false,
        isEvening: false
      });
    });
    afternoon.forEach((slot) => {
      allSLotsArr.push({
        ...slot,
        isMorning: false,
        isAfternoon: true,
        isEvening: false
      });
    });
    evening.forEach((slot) => {
      allSLotsArr.push({
        ...slot,
        isMorning: false,
        isAfternoon: false,
        isEvening: true
      });
    });

    try {
      //
      const db = getDatabase(firebaseConfig);
      const formatted = getDateIntoDDMMYYYY(date);
      const dbRef = ref(db, `availability/${formatted}`);
      onValue(dbRef, (snapshot) => {
        const data = snapshot.val();
        const correctedData = data || { isHoliday: false, slots: [] };
        if (!correctedData.slots) {
          correctedData.slots = [];
        }
        arrangeAvailableSlots(allSLotsArr, correctedData, date);
      });
      setCurrentDBRef(dbRef);
    } catch (error) {
      //
    }
  };

  const fetchAvailableSampleCollectors = async (selectedCollector, slot) => {
    const tempSlot = slot || currentSlot;
    const key = 'updatable';
    if (!slot) {
      message.open({
        key,
        type: 'loading',
        content: 'Fetching collectors...'
      });
    }
    const fetchedAvailableSampleCollectors = await getAvailableSampleCollectors(
      tempSlot.date,
      tempSlot.time.toString()
    );
    setCurrentSlot({
      date: tempSlot.date,
      time: tempSlot.time.toString()
    });
    const currentSelectedCollector = selectedCollector;
    const collectors = [];
    let adder = 0;
    if (currentAssignedCollector) {
      adder = 1;
      collectors[0] = {
        value: currentSelectedCollector.id,
        label: currentSelectedCollector.name
      };
    }
    for (let i = 0; i < fetchedAvailableSampleCollectors.length; i += 1) {
      collectors[i + adder] = {
        value: fetchedAvailableSampleCollectors[i].id,
        label: fetchedAvailableSampleCollectors[i].name
      };
    }
    try {
      message.destroy(key);
    } catch (error) {
      //
    }
    if (slot) setCurrentAssignedCollector(selectedCollector);
    setAvailableSampleCollectors(collectors);
  };

  // FETCH ALL SAMPLE COLLECTORS
  const fetchSampleCollectors = async () => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Fetching collectors...'
    });
    try {
      //
      const response = await getSampleCollectors();
      setAllSampleCollectors(response.sampleCollectors);
    } catch (error) {
      //
    }
    message.destroy(key);
  };

  const isSampleCollectorAvailable = (collector) => {
    // Check if the collector is in the list of available collectors
    return availableCollectors.some(
      (availableCollector) => availableCollector.id === collector.id
    );
  };

  const selectedMethod = (collector) => {
    const isAvailable = isSampleCollectorAvailable(collector);
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        {collector.name}{' '}
        {isAvailable ? (
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        ) : (
          <CheckCircleTwoTone twoToneColor="#eb5757" />
        )}
      </div>
    );
  };

  const fetchAvailableData = async () => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Processing...'
    });
    const { bookedTimeSlot } = order;
    const fetchedAvailableSampleCollectors = await getAvailableSampleCollectors(
      bookedTimeSlot.selectedDate,
      bookedTimeSlot.slot.startTime.toString()
    );
    setAvailableCollectors(fetchedAvailableSampleCollectors);
    setForcedReplace(true);
    message.destroy(key);
  };

  const allSampleCollectorsOptions = [];
  for (let i = 0; i < allSampleCollectors.length; i += 1) {
    allSampleCollectorsOptions.push(
      <Select.Option
        value={`${allSampleCollectors[i].id}`}
        label={allSampleCollectors[i].name}
      >
        {selectedMethod(allSampleCollectors[i])}
      </Select.Option>
    );
  }

  const determineStage = (tempOrder) => {
    const currentOrder = tempOrder || order;
    const { orderTransitStatus } = currentOrder;
    const colors = { ...colorChange };
    switch (orderTransitStatus) {
      case 'COLLECTED':
        colors.samplesCollected = true;
        break;
      case 'SEND_TO_LAB':
        colors.samplesCollected = true;
        colors.sendToLab = true;
        break;
      case 'REPORT_UPLOADED':
        colors.samplesCollected = true;
        colors.sendToLab = true;
        colors.reportUploaded = true;
        break;
      case 'REPORT_PUBLISHED':
        colors.samplesCollected = true;
        colors.sendToLab = true;
        colors.reportUploaded = true;
        colors.reportPublished = true;
        break;
      case 'DELIVERED':
        colors.samplesCollected = true;
        colors.sendToLab = true;
        colors.reportUploaded = true;
        colors.reportPublished = true;
        colors.delivered = true;
        break;
      case 'PENDING':
        // SOMETHING
        // ONLY BOOKED
        break;
      default:
        // HANDLE CANCEL
        break;
    }
    setColorChange(colors);
  };

  const fetchOrder = (orderId) => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Wait...! Loading order'
    });
    getOrderByIdAsAdmin(orderId).then((response) => {
      setOrder(response.order);
      setTransactionId(response.order.transactions);
      setTests(response.order.tests);
      getCustomerPatients(response.order.customer.id, {
        skip: 0,
        limit: 100
      }).then((res) => {
        setPatients(res.patients);
      });
      const { bookedTimeSlot } = response.order;
      fetchAvailableSampleCollectors(response.order.sampleCollectionPartner, {
        date: bookedTimeSlot.selectedDate,
        time: bookedTimeSlot.slot.startTime
      });
      setIsLoading(false);
      determineStage(response.order);
    });
    getOrderLogs({ skip: 0, limit: 3 }, orderId).then((res) => {
      setLogs(res);
    });
  };

  const onRejectionFormSubmit = async (formValues) => {
    setFormFlag('SINGLE_REPORT');
    setIsRejecting(true);
    const result = await rejectReportAsAdmin({
      orderId: order.id,
      rejectionReason: formValues.rejectionReason,
      testId: currentTestId
    });
    const key = 'updatable';
    if (result) {
      message.open({
        key,
        type: 'success',
        content: 'Report rejected',
        duration: 1
      });
      fetchOrder(order.id);
    }
    setIsRejecting(false);
    setFormFlag('WHOLE_REPORT');
    setOpenRejectionModal(false);
  };

  const onRejectionWholeFormSubmit = async (formValues) => {
    setIsRejecting(true);
    const result = await rejectWholeReportAsAdmin(
      order.id,
      formValues.rejectionReason
    );
    const key = 'updatable';
    if (result) {
      message.open({
        key,
        type: 'success',
        content: 'Report rejected',
        duration: 1
      });
      fetchOrder(order.id);
    }
    setIsRejecting(false);
    setOpenRejectionWholeModal(false);
  };

  const fetchAddress = async (ID) => {
    const addressResponse = await getCustomerAddressesAsAdmin(
      {
        skip: 0,
        limit: 10000
      },
      ID
    );
    setAddress(addressResponse);
  };

  if (order) {
    fetchAddress(order.customer.id);
  }

  useEffect(() => {
    const orderId = searchParams.get('orderId');
    fetchOrder(orderId);
    getMaximumPerson();
    fetchSampleCollectors();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { Panel } = Collapse;

  // CANCEL ORDER
  const cancelOrder = async () => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Canceling order'
    });
    const orderId = searchParams.get('orderId');
    await cancelOrderAsAdmin(orderId);
    fetchOrder(orderId);
    setIsCancelModal(false);
    message.destroy(key);
  };

  const cancelOrderForcefully = async () => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Canceling order'
    });
    const orderId = searchParams.get('orderId');
    await cancelOrderForcefullyAsAdmin(orderId);
    fetchOrder(orderId);
    message.destroy(key);
  };

  const handleCancelDetails = () => {
    setViewDetails(false);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true
  });

  const generateInvoice = async () => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Generating Invoice'
    });
    const response = await getInvoiceByOrderId(order.id);
    message.open({
      key,
      type: 'success',
      content: 'Invoice Generated'
    });
    // eslint-disable-next-line no-undef
    window.open(response.url, '_blank');
  };

  const addressOptions = [];
  for (let i = 0; i < address.length; i += 1) {
    addressOptions.push({
      value: address[i].id,
      label: `${address[i].name},${address[i].addressLine1},${address[i].addressLine2},${address[i].city},${address[i].pincode},${address[i].state} `
    });
  }

  const patientsSelectData = [];
  for (let i = 0; i < patients.length; i += 1) {
    patientsSelectData.push({
      value: patients[i].id,
      label: patients[i].name
    });
  }

  const handleCopyCode = (code) => {
    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(code).then(() => {
      message.success('Code copied to clipboard');
    });
  };

  const checkIfUploadDisabled = () => {
    if (order) {
      const testsLength = order.orderSummary.tests.length;
      const reportsLength = order.reports.length;

      if (order.orderStatus === 'COMPLETED') {
        return true;
      }

      if (testsLength !== reportsLength) {
        return true;
      }
      if (testsLength === reportsLength) {
        const allReportsApproved = order.reports.every(
          (report) => report.isApproved
        );

        if (allReportsApproved) {
          return true;
        }
      }
    }
  };

  // ADD REMOVE TESTS
  const fetchLiveTests = async (currentSearch) => {
    if (currentSearch === '') {
      setShowingTestResults(false);
      return;
    }
    setShowingTestResults(true);
    setIsFetchingTests(true);
    try {
      const response = await getLiveTestsAndPackages({
        skip: 0,
        limit: 3,
        query: currentSearch
      });
      const { tests: ts } = response;
      const currentFetchedTests = [];
      for (let i = 0; i < ts.length; i += 1) {
        const test = ts[i];
        const { id } = test;
        let isAlreadySelected = false;
        for (let j = 0; j < selectedTests.length; j += 1) {
          if (selectedTests[j].id === id) {
            isAlreadySelected = true;
            break;
          }
        }
        currentFetchedTests.push({
          isSelected: isAlreadySelected,
          test
        });
      }

      setFetchedTests(currentFetchedTests);
    } catch (error) {
      //
    }
    setIsFetchingTests(false);
  };

  const handleCancelADdTestsModal = () => {
    clearSearchField('Tests');
    setSelectedTests([]);
    setAddTestModal(false);
  };

  const uploadSelectedTests = async () => {
    setIsSavingData(true);
    const testsId = [];
    for (let i = 0; i < selectedTests.length; i += 1) {
      testsId.push(selectedTests[i].id);
    }
    const orderId = searchParams.get('orderId');
    const data = {
      orderId,
      tests: testsId
    };
    await updateOrderAsAdmin(data);
    setIsSavingData(false);
    fetchOrder(orderId);
    handleCancelADdTestsModal();
  };

  const cancelBtnHide =
    order && ['CANCELLED', 'COMPLETED'].includes(order.orderStatus);

  const isAllowedToReassign = order && ['BOOKED'].includes(order.orderStatus);

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Button
              className="back-btn"
              type="default"
              onClick={() => {
                navigate(-1);
              }}
            >
              <Back />
            </Button>
            <Typography.Text className="main-header-title">
              {order ? order.orderId : 'Order Reports'}
            </Typography.Text>
          </Space>
          <div className="btn-container">
            {order?.paymentVia === 'ONLINE' ? (
              <Button
                className="orderHistory"
                type="default"
                onClick={() => {
                  const url = `${ROUTES.ORDER_TRANSACTION}?orderId=${order.id}`;
                  navigate(url);
                }}
              >
                View transactions
              </Button>
            ) : null}
            <Button onClick={() => setViewDetails(true)} type="link">
              View Details
            </Button>
            {isAllowedToReassign && (
              <Button
                className="cancel-refund-btn"
                onClick={() => setIsCancelModal(true)}
              >
                Cancel & Refund
              </Button>
            )}
            {order?.invoiceUrl && order?.invoiceUrl?.length !== 0 ? (
              <Button
                className="create-btn invoice-btn"
                onClick={generateInvoice}
              >
                Download Invoice
              </Button>
            ) : null}
          </div>
        </div>
      </Header>
      <div className="main-content main-content-order-reports">
        {isLoading ? (
          <LoaderComponent />
        ) : (
          <Row className="order-report-content">
            <Col span={17} className="left-content">
              <div className="person-details">
                <div className="person-name">
                  <Profile />
                  {order.patient.name.toUpperCase()} <div className="dot" />{' '}
                  {order.patient.age}{' '}
                  <span>
                    {order.patient.sex === 'MALE'
                      ? 'M'
                      : order.patient.sex === 'FEMALE'
                      ? 'F'
                      : 'O'}
                  </span>
                  <span>{order?.customer?.mobileNumber}</span>
                  <Button
                    type="link"
                    onClick={() => {
                      const url = `${ROUTES.CUSTOMER_DETAIL}?customerId=${order?.customer?.id}`;
                      navigate(url);
                    }}
                  >
                    View Profile
                  </Button>
                </div>
                <div className="person-location">
                  <Location /> {order.address.addressLine1},
                  {order.address.addressLine2}, {order.address.city} -
                  {order.address.pincode}, {order.address.state}
                </div>
              </div>
              <div className="timeline-section">
                <div className="timeline-tabs">
                  <Row>
                    <Col className="col">
                      <span> test booked</span>
                      <img src={StartImg} alt=".." />
                    </Col>
                    <Col className="col">
                      <span>samples collected</span>
                      <img
                        src={RegularImg}
                        className={
                          colorChange.samplesCollected ? 'selected' : null
                        }
                        alt=".."
                      />
                    </Col>
                    <Col className="col">
                      <span> sent to lab</span>
                      <img
                        src={RegularImg}
                        className={colorChange.sendToLab ? 'selected' : null}
                        alt=".."
                      />
                    </Col>
                    <Col className="col">
                      <span> report uploaded</span>
                      <img
                        src={RegularImg}
                        className={
                          colorChange.reportUploaded ? 'selected' : null
                        }
                        alt=".."
                      />
                    </Col>
                    <Col className="col">
                      <span> report published</span>
                      <img
                        src={RegularImg}
                        className={
                          colorChange.reportPublished ? 'selected' : null
                        }
                        alt=".."
                      />
                    </Col>
                    {order.deliverReport ? (
                      <Col className="col">
                        <span> delivered</span>
                        <img
                          src={EndImg}
                          className={colorChange.delivered ? 'selected' : null}
                          alt=".."
                        />
                      </Col>
                    ) : null}
                  </Row>
                </div>
                {logs
                  ? logs.logs.map((log) => {
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <div className="log-row">
                          <div className="time-log">
                            {moment(log.updatedAt).format('D/MM/YYYY')} |{' '}
                            {moment(log.updatedAt).format('hh:mm a')}
                          </div>
                          <div
                            className="message-log"
                            dangerouslySetInnerHTML={{ __html: log.message }}
                          />
                        </div>
                      );
                    })
                  : null}

                {logs?.total > 3 ? (
                  <Button
                    className="show-more"
                    type="text"
                    onClick={async () => {
                      const key = 'updatable';
                      message.open({
                        key,
                        type: 'loading',
                        content: 'Fetching logs'
                      });
                      const fetchedLogs = await getOrderLogs(
                        { skip: 0, limit: 10000 },
                        order.id
                      );
                      setAllLogs(fetchedLogs.logs);
                      setTimeout(() => {
                        message.destroy(key);
                        setIsLogModalOpen(true);
                      }, 200);
                    }}
                  >
                    show more logs
                  </Button>
                ) : null}

                {order?.reports &&
                  [
                    'SEND_TO_LAB',
                    'REPORT_UPLOADED',
                    'REPORT_PUBLISHED',
                    'COMPLETED',
                    'DELIVERED'
                  ].includes(order?.orderTransitStatus) && (
                    <div className="report-list">
                      <div className="report-list-item">
                        <span>
                          <b>Upload Full Report</b>
                        </span>
                        <div
                          style={{
                            display: 'flex'
                          }}
                        >
                          {order?.wholeReport?.reportUrl && (
                            <Button
                              onClick={() => {
                                const { reportUrl } = order.wholeReport;
                                // eslint-disable-next-line no-undef
                                window.open(reportUrl, '_blank');
                              }}
                              ghost
                            >
                              View
                            </Button>
                          )}
                          {order?.wholeReport?.reportUrl &&
                            !order?.wholeReport?.isApproved && (
                              <div style={{ width: '10px' }} />
                            )}
                          {order?.wholeReport?.reportUrl &&
                            !order?.wholeReport?.isApproved && (
                              <Button
                                onClick={() => {
                                  setOpenRejectionWholeModal(true);
                                  setCurrentTestId(order?.wholeReport?.id);
                                }}
                                type="danger"
                                ghost
                                shape="circle"
                              >
                                <CloseOutlined />
                              </Button>
                            )}
                          {order?.wholeReport?.reportUrl &&
                            !order?.wholeReport?.isApproved && (
                              <div style={{ width: '10px' }} />
                            )}
                          {order?.wholeReport?.reportUrl &&
                            !order?.wholeReport?.isApproved && (
                              <Button
                                onClick={async () => {
                                  const key = 'updatable';
                                  message.open({
                                    key,
                                    type: 'loading',
                                    content: 'Wait...! Approving report',
                                    duration: 0
                                  });
                                  const result =
                                    await approveWholeReportAsAdmin(order.id);
                                  if (result) {
                                    message.open({
                                      key,
                                      type: 'success',
                                      content: 'Report approved',
                                      duration: 1
                                    });
                                    fetchOrder(order.id);
                                  }
                                }}
                                type="primary"
                                ghost
                                shape="circle"
                              >
                                <CheckOutlined />
                              </Button>
                            )}
                          {order?.wholeReport?.isApproved && (
                            <div style={{ width: '10px' }} />
                          )}
                          {order?.wholeReport?.rejectionReason && (
                            <Tooltip
                              className="tooltipNo"
                              color="red"
                              placement="topLeft"
                              title={order?.wholeReport?.rejectionReason}
                            >
                              <WarningOutlined
                                style={{
                                  fontSize: '20px',
                                  color: 'red'
                                }}
                              />
                            </Tooltip>
                          )}
                          {order?.wholeReport?.rejectionReason && (
                            <div style={{ width: '10px' }} />
                          )}
                          {order?.wholeReport?.isApproved && (
                            <TickCircle
                              style={{
                                height: '35px',
                                width: '35px'
                              }}
                            />
                          )}
                          {!order?.wholeReport?.reportUrl && (
                            <Upload
                              name="upload-pdf"
                              onChange={async (selectedFile) => {
                                const key = 'updatable';
                                message.open({
                                  key,
                                  type: 'loading',
                                  content: 'Wait...! uploading pdf',
                                  duration: 0
                                });
                                const pdfURL = await onPDFUpload(selectedFile);
                                const result = await uploadWholeReportAsAdmin({
                                  orderId: order.id,
                                  reportFileUrl: pdfURL
                                });
                                if (result) {
                                  message.open({
                                    key,
                                    type: 'success',
                                    content: 'PDF Uploaded successfully',
                                    duration: 1
                                  });
                                  fetchOrder(order.id);
                                } else {
                                  message.open({
                                    key,
                                    type: 'error',
                                    content: 'PDF Failed to upload',
                                    duration: 1
                                  });
                                }
                              }}
                              accept=".pdf"
                              showUploadList={false}
                              beforeUpload={beforeUpload}
                            >
                              <Button disabled={checkIfUploadDisabled()} ghost>
                                Upload
                              </Button>
                            </Upload>
                          )}
                        </div>
                      </div>

                      {order?.reports.map((report) => {
                        let currentTest;
                        for (let i = 0; i < tests.length; i += 1) {
                          if (tests[i].id === report.test) {
                            currentTest = tests[i];
                            break;
                          }
                        }
                        return (
                          <div className="report-list-item" key={report.test}>
                            <span>{currentTest?.title}.pdf</span>
                            <div
                              style={{
                                display: 'flex'
                              }}
                            >
                              {report.reportUrl && (
                                <Button
                                  disabled={order?.wholeReport}
                                  onClick={() => {
                                    const { reportUrl } = report;
                                    // eslint-disable-next-line no-undef
                                    window.open(reportUrl, '_blank');
                                  }}
                                  ghost
                                >
                                  View
                                </Button>
                              )}
                              {report.reportUrl && !report.isApproved && (
                                <div style={{ width: '10px' }} />
                              )}
                              {report.reportUrl && !report.isApproved && (
                                <Button
                                  onClick={() => {
                                    setOpenRejectionModal(true);
                                    setCurrentTestId(report.test);
                                  }}
                                  type={
                                    order?.wholeReport ? 'default' : 'danger'
                                  }
                                  disabled={order?.wholeReport}
                                  ghost
                                  shape="circle"
                                >
                                  <CloseOutlined />
                                </Button>
                              )}
                              {report.reportUrl && !report.isApproved && (
                                <div style={{ width: '10px' }} />
                              )}
                              {report.reportUrl && !report.isApproved && (
                                <Button
                                  onClick={async () => {
                                    const key = 'updatable';
                                    message.open({
                                      key,
                                      type: 'loading',
                                      content: 'Report approving',
                                      duration: 1
                                    });
                                    const result = await approveReportAsAdmin({
                                      orderId: order.id,
                                      testId: report.test
                                    });
                                    if (result) {
                                      message.open({
                                        key,
                                        type: 'success',
                                        content: 'Report approved',
                                        duration: 1
                                      });
                                      fetchOrder(order.id);
                                    }
                                  }}
                                  type="primary"
                                  disabled={order?.wholeReport}
                                  ghost
                                  shape="circle"
                                >
                                  <CheckOutlined />
                                </Button>
                              )}
                              {report.isApproved && (
                                <div style={{ width: '10px' }} />
                              )}
                              {report.rejectionReason && (
                                <Tooltip
                                  className="tooltipNo"
                                  color="red"
                                  placement="topLeft"
                                  title={report.rejectionReason}
                                >
                                  <WarningOutlined
                                    style={{
                                      fontSize: '20px',
                                      color: 'red'
                                    }}
                                  />
                                </Tooltip>
                              )}
                              {report.rejectionReason && (
                                <div style={{ width: '10px' }} />
                              )}
                              {report.isApproved && (
                                <TickCircle
                                  style={{
                                    height: '35px',
                                    width: '35px'
                                  }}
                                />
                              )}
                              {!report.reportUrl && (
                                <Upload
                                  name="upload-pdf"
                                  onChange={async (selectedFile) => {
                                    const key = 'updatable';
                                    message.open({
                                      key,
                                      type: 'loading',
                                      content: 'Wait...! uploading pdf',
                                      duration: 0
                                    });
                                    const pdfURL = await onPDFUpload(
                                      selectedFile
                                    );
                                    const result = await uploadReportAsAdmin({
                                      orderId: order.id,
                                      reportFileUrl: pdfURL,
                                      testId: report.test
                                    });
                                    if (result) {
                                      message.open({
                                        key,
                                        type: 'success',
                                        content: 'PDF Uploaded successfully',
                                        duration: 1
                                      });
                                      fetchOrder(order.id);
                                    } else {
                                      message.open({
                                        key,
                                        type: 'error',
                                        content: 'PDF Failed to upload',
                                        duration: 1
                                      });
                                    }
                                  }}
                                  accept=".pdf"
                                  showUploadList={false}
                                  beforeUpload={beforeUpload}
                                >
                                  <Button disabled={order?.wholeReport} ghost>
                                    Upload
                                  </Button>
                                </Upload>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                {order.orderStatus === 'REPORT_PUBLISHED' && <Divider />}
                {order.orderStatus === 'REPORT_PUBLISHED' && (
                  <div
                    style={{
                      textAlign: 'right',
                      padding: '10px 0px'
                    }}
                  >
                    <Button
                      loading={isDeliveringReport}
                      onClick={async () => {
                        setIsDeliveringReport(true);
                        await markReportAsDelivered({
                          orderId: order.id
                        });
                        setIsDeliveringReport(false);
                        fetchOrder(order.id);
                      }}
                      htmlType="submit"
                      className="create-btn"
                    >
                      Deliver Report
                    </Button>
                  </div>
                )}
              </div>
              <div className="order-tests">
                <Space
                  direction="vertical"
                  style={{
                    width: '100%'
                  }}
                >
                  {tests
                    ? tests.map((test) => {
                        return (
                          <Collapse
                            expandIconPosition="end"
                            bordered={false}
                            key={test.id}
                            collapsible="header"
                          >
                            <Panel
                              header={
                                <b>
                                  {test.title.toUpperCase()} - ₹{test.price}
                                </b>
                              }
                              key={test.id}
                            >
                              <p> Equipments required :</p>
                              <ul>
                                {test.equipmentsRequired
                                  ? test.equipmentsRequired.map((eqp) => {
                                      return (
                                        <li key={eqp.equipment.id}>
                                          {eqp.equipment.name}
                                        </li>
                                      );
                                    })
                                  : null}
                              </ul>
                            </Panel>
                          </Collapse>
                        );
                      })
                    : null}
                </Space>
              </div>
              {/* {isAllowedToReassign && (
                <Space
                  direction="vertical"
                  style={{
                    width: '100%',
                    marginBottom: '20px'
                  }}
                >
                  <Button
                    style={{
                      width: '100px',
                      // margin: '10px',
                      border: 'none',
                      color: 'white',
                      backgroundColor: '#232e78'
                    }}
                    onClick={() => {
                      console.log({ tests });
                      setSelectedTests(tests);
                      setAddTestModal(true);
                    }}
                  >
                    Edit Order
                  </Button>
                </Space>
              )} */}

              <Modal
                title="Update Order"
                centered
                className="create-test-modal"
                open={addTestModal}
                onCancel={handleCancelADdTestsModal}
              >
                <Divider />
                <div className="tests-modal-search">
                  <SearchComponent
                    name="Tests"
                    debounceTime={DEBOUNCE_TIME}
                    getData={fetchLiveTests}
                  />

                  {!isFetchingTests && showingTestResults && (
                    <div className="search-result" id="test-searcher">
                      {fetchedTests.length <= 0 ? (
                        <Empty />
                      ) : (
                        fetchedTests.map((currentTest) => {
                          const { isSelected, test } = currentTest;
                          return (
                            <div className="tests-list" key={test.id}>
                              <Typography.Text>
                                {test.title} - ₹{test.price}
                              </Typography.Text>
                              {isSelected ? (
                                <Button
                                  type="text"
                                  onClick={() => {
                                    const orderTests = selectedTests.slice();
                                    for (
                                      let i = 0;
                                      i < orderTests.length;
                                      i += 1
                                    ) {
                                      if (orderTests[i].id === test.id) {
                                        orderTests.splice(i, 1);
                                      }
                                    }
                                    const tempFetchedTests =
                                      fetchedTests.slice();
                                    for (
                                      let i = 0;
                                      i < tempFetchedTests.length;
                                      i += 1
                                    ) {
                                      if (
                                        tempFetchedTests[i].test.id === test.id
                                      ) {
                                        tempFetchedTests[i] = {
                                          ...tempFetchedTests[i],
                                          isSelected: false
                                        };
                                        break;
                                      }
                                    }
                                    setSelectedTests(tests);
                                    setFetchedTests(tempFetchedTests);
                                  }}
                                >
                                  Remove
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => {
                                    const orderTests = selectedTests.slice();
                                    orderTests.push(test);
                                    const tempFetchedTests =
                                      fetchedTests.slice();
                                    for (
                                      let i = 0;
                                      i < tempFetchedTests.length;
                                      i += 1
                                    ) {
                                      if (
                                        tempFetchedTests[i].test.id === test.id
                                      ) {
                                        tempFetchedTests[i] = {
                                          ...tempFetchedTests[i],
                                          isSelected: true
                                        };
                                        break;
                                      }
                                    }
                                    setSelectedTests(orderTests);
                                    setFetchedTests(tempFetchedTests);
                                  }}
                                  type="default"
                                >
                                  Add
                                </Button>
                              )}
                            </div>
                          );
                        })
                      )}
                    </div>
                  )}
                </div>
                <div className="selected-test-container category-container">
                  {selectedTests?.length <= 0 ? (
                    <Empty />
                  ) : (
                    selectedTests.map((currentTest) => {
                      const { id, title, price } = currentTest;
                      return (
                        <div className="tests-list" key={id}>
                          <Typography.Text>
                            {title} - ₹{price}
                          </Typography.Text>
                          {selectedTests?.length > 1 && (
                            <Button
                              type="text"
                              onClick={() => {
                                const orderTests = selectedTests.slice();
                                for (let i = 0; i < orderTests.length; i += 1) {
                                  if (orderTests[i].id === id) {
                                    orderTests.splice(i, 1);
                                  }
                                }
                                setSelectedTests(orderTests);
                              }}
                            >
                              Remove
                            </Button>
                          )}
                        </div>
                      );
                    })
                  )}
                </div>
                <Divider />
                <Button
                  loading={isSavingData}
                  className="create-btn offer-save-btn"
                  onClick={uploadSelectedTests}
                >
                  SAVE
                </Button>
              </Modal>

              <Space
                direction="vertical"
                style={{
                  width: '100%'
                }}
              >
                <div className="address-selector">
                  <span
                    style={{
                      margin: '10px',
                      fontSize: '16px'
                    }}
                  >
                    <b> CHANGE ADDRESS</b>
                  </span>
                  <Select
                    disabled={order?.orderStatus !== 'BOOKED'}
                    defaultValue={order?.address?.id}
                    onChange={(change) => {
                      setAddressId(change);
                      setDisableAddressBtn(false);
                      setAddressError(true);
                    }}
                    placeholder="Select address"
                    options={addressOptions}
                  />
                  <Button
                    loading={addressLoading}
                    disabled={
                      order?.orderStatus !== 'BOOKED' || disableAddressBtn
                    }
                    onClick={async () => {
                      setAddressLoading(true);
                      await updateOrderAddress(order?.id, addressId);
                      setAddressLoading(false);
                      setAddressId('');
                      setAddressError(false);
                      setDisableAddressBtn(true);
                      fetchOrder(order?.id);
                    }}
                    style={{
                      width: '100px',
                      margin: '10px',
                      border: 'none',
                      color: 'white',
                      backgroundColor: '#232e78'
                    }}
                  >
                    SAVE
                  </Button>
                  {addressError ? (
                    <span className="address-error">
                      Please save to change address
                    </span>
                  ) : null}
                </div>
              </Space>
              <Space
                direction="vertical"
                style={{
                  width: '40%',
                  marginTop: '20px'
                }}
              >
                <div className="address-selector">
                  <span
                    style={{
                      margin: '10px',
                      fontSize: '16px'
                    }}
                  >
                    <b> CHANGE PATIENT</b>
                  </span>
                  <Select
                    disabled={order?.orderStatus !== 'BOOKED'}
                    defaultValue={order?.patient?.id}
                    onChange={(change) => {
                      setDisablePatientBtn(false);
                      setPatientId(change);
                      setPatientError(true);
                    }}
                    placeholder="Select patient"
                    options={patientsSelectData}
                  />
                  <Button
                    loading={patientUpdateLoading}
                    disabled={
                      order?.orderStatus !== 'BOOKED' || disablePatientBtn
                    }
                    onClick={async () => {
                      setPatientUpdateLoading(true);
                      await updatePatientInOrder(order?.id, patientId);
                      setPatientUpdateLoading(false);
                      setPatientId('');
                      setPatientError(false);
                      setDisablePatientBtn(true);
                      fetchOrder(order?.id);
                    }}
                    style={{
                      width: '100px',
                      margin: '10px',
                      border: 'none',
                      color: 'white',
                      backgroundColor: '#232e78'
                    }}
                  >
                    SAVE
                  </Button>
                  {patientError ? (
                    <span className="address-error">
                      Please save to update patient
                    </span>
                  ) : null}
                </div>
              </Space>
            </Col>
            <Col
              span={7}
              className="right-content"
              style={{
                padding: '0px 12px'
              }}
            >
              <div className="payment-card">
                <Typography.Text>Payment details:</Typography.Text>
                <div className="payment-box">
                  <div className="left-side">
                    <div>Actual price:</div>
                    <div>Delivery:</div>
                    <div> Coupon:</div>
                    <div> Convenience Fees:</div>
                    <div>
                      <b>Total amt:</b>
                    </div>
                    <div>Coins:</div>
                    <div>
                      <b>Payable amt:</b>
                    </div>
                    <div>Status:</div>
                    <div>Payment mode:</div>
                    <div>Order Status:</div>
                  </div>
                  <div className="right-side">
                    <div>
                      ₹ {order?.orderSummary?.cartTotalPrice.toFixed(2)}&nbsp;
                      <strike>
                        {(
                          order?.orderSummary?.cartTotalPrice +
                          order?.orderSummary?.overallTestDiscount
                        ).toFixed(2)}
                      </strike>
                    </div>
                    <div>
                      {order?.orderSummary?.delivery?.isEnabled
                        ? `₹ ${order?.orderSummary?.delivery?.charges.toFixed(
                            2
                          )}`
                        : '-'}
                    </div>
                    <div>
                      {order?.orderSummary?.offer?.isApplied ? (
                        <span
                          style={{
                            cursor: 'pointer'
                          }}
                          onClick={() =>
                            handleCopyCode(order?.offerApplied?.couponCode)
                          }
                        >
                          {order?.offerApplied?.couponCode} (₹
                          {order?.orderSummary?.discountByCoupon.toFixed(2)})
                        </span>
                      ) : (
                        '-'
                      )}
                    </div>
                    <div>{`₹ ${order?.orderSummary?.convenienceFees}`}</div>
                    <div>
                      <b>{`₹ ${(
                        order?.orderSummary?.subTotal +
                        (order?.orderSummary?.coins?.used || 0)
                      ).toFixed(2)}`}</b>
                    </div>
                    <div>{`₹ ${(order?.orderSummary?.coins.used || 0).toFixed(
                      2
                    )}`}</div>
                    <div>
                      <b>{`₹ ${order?.orderSummary?.subTotal.toFixed(2)}`}</b>
                    </div>
                    <div>{getTag(order.paymentStatus)}</div>
                    <div>{getPayVia(order.paymentVia)}</div>
                    <div>
                      {getOrderStatusTags(order.orderStatus)}
                      {/* <Tag>{order.orderStatus}</Tag> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="sample-collection-card">
                <Typography.Text>Schedule</Typography.Text>
                <div className="payment-content">
                  <Divider />
                  <div>
                    Sample collection scheduled at&nbsp;
                    {order.bookedTimeSlot.slot.name} on&nbsp;
                    {new Date(order.scheduledAt).getDate()}{' '}
                    {monthNames[new Date(order.scheduledAt).getMonth()]},{' '}
                    {new Date(order.scheduledAt).getFullYear()}.
                  </div>
                </div>
                {isAllowedToReassign && <Divider />}
                {isAllowedToReassign && (
                  <div className="save-btn">
                    <Button
                      onClick={() => {
                        const now = new Date();
                        const cancelDate = new Date(order.cancellableUpTo);
                        const remainingSec = (cancelDate - now) / 1000;
                        if (cancelDate > now) {
                          message.error(
                            `Can't reschedule till ${remainingSec} secs`
                          );
                          return;
                        }
                        monitorSlots(now);
                        setCurrentDate(now);
                        setRefetchingAvailableSlots(true);
                        setIsRescheduleModal(true);
                      }}
                    >
                      Reschedule
                    </Button>
                  </div>
                )}
              </div>
              <div className="sample-collection-card">
                <Typography.Text>Sample Collection</Typography.Text>
                <div className="payment-content">
                  <Divider />
                  <p>Sample collection person</p>
                  <div className="assign-person-name">
                    <Select
                      onDropdownVisibleChange={(change) => {
                        if (change)
                          fetchAvailableSampleCollectors(
                            order.sampleCollectionPartner
                          );
                      }}
                      onChange={(change) => {
                        for (
                          let i = 0;
                          i < availableSampleCollectors.length;
                          i += 1
                        ) {
                          const temp = availableSampleCollectors[i];
                          if (temp.value === change) {
                            setCurrentAssignedCollector({
                              id: temp.value,
                              name: temp.label
                            });
                            break;
                          }
                        }
                        setClickSaveToUpdate(true);
                        //
                      }}
                      disabled={!isAllowedToReassign}
                      value={
                        currentAssignedCollector?.id &&
                        currentAssignedCollector?.name
                      }
                      placeholder="Select sample collector partner"
                      options={availableSampleCollectors}
                    />
                  </div>
                </div>
                {isAllowedToReassign && <Divider />}
                {isAllowedToReassign && (
                  <div
                    className="save-btn"
                    style={{
                      alignItems: 'center',
                      gap: '10px'
                    }}
                  >
                    {clickSaveToUpdate ? (
                      <span className="saveUpdate">Please save to proceed</span>
                    ) : (
                      <>&nbsp;</>
                    )}
                    <Button
                      onClick={async () => {
                        const now = new Date();
                        const cancelDate = new Date(order.cancellableUpTo);
                        const remainingSec = (cancelDate - now) / 1000;
                        if (cancelDate > now) {
                          message.error(
                            `Can't re-assign till ${remainingSec} secs`
                          );
                          return;
                        }
                        const key = 'updatable';
                        message.open({
                          key,
                          type: 'loading',
                          content: 'Replacing collectors...',
                          duration: 0
                        });
                        await replaceSampleCollector({
                          orderId: order.id,
                          sampleCollectorId: currentAssignedCollector.id,
                          data: currentSlot.date,
                          time: currentSlot.time
                        });
                        setClickSaveToUpdate(false);
                        message.destroy(key);
                        fetchOrder(order.id);
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      onClick={() => {
                        fetchAvailableData();
                      }}
                      style={{
                        backgroundColor: '#eb5757'
                      }}
                    >
                      FORCE REPLACE
                    </Button>
                  </div>
                )}
              </div>
              {!cancelBtnHide && (
                <div className="sample-collection-card">
                  <div className="cancel-content">
                    <span>Want to cancel this order?</span>
                    <div className="cancel-btn">
                      <Popconfirm
                        title="Cancel order"
                        description="This will cancel this order forcefully."
                        onConfirm={() => {
                          cancelOrderForcefully();
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button>Cancel order</Button>
                      </Popconfirm>
                    </div>
                    <span>
                      <b>
                        "Please note: Canceling this order will cancel the order
                        itself, but it will not trigger a refund, release the
                        booking slot, or release the sample collector."
                      </b>
                    </span>
                  </div>
                </div>
              )}
              {order?.orderStatus === 'COMPLETED' && (
                <div className="sample-collection-card">
                  <Typography.Text>User Review</Typography.Text>
                  <div className="payment-content">
                    <Divider />
                    <Rate defaultValue={order?.testimonial?.ratings} disabled />
                    <p>{order?.testimonial?.testimonial}</p>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        )}
      </div>
      {/* REJECTION MODAL */}
      <Modal
        title={<div>Rejection reason</div>}
        open={openRejectionWholeModal}
        cancelButtonProps={{ className: 'no-display' }}
        okButtonProps={{
          className: 'no-display'
        }}
        footer={null}
        onCancel={() => setOpenRejectionWholeModal(false)}
        onOk={() => setOpenRejectionWholeModal(false)}
      >
        <Form layout="vertical" onFinish={onRejectionWholeFormSubmit}>
          <Form.Item label="Reason" name="rejectionReason" rules={[required]}>
            <Input />
          </Form.Item>
          <Button
            loading={isRejecting}
            htmlType="submit"
            className="create-btn-modal"
          >
            Reject
          </Button>
        </Form>
      </Modal>
      {/* FORCED REPLACE SAMPLE COLLECTOR MODAL */}
      <Modal
        title="Replace sample collector"
        centered
        open={forcedReplace}
        cancelButtonProps={{ className: 'no-display' }}
        okButtonProps={{
          type: 'primary',
          className: 'replace-sample-collector'
        }}
        okText="Replace"
        onCancel={() => setForcedReplace(false)}
        onOk={async () => {
          if (forcedSampleCollectorId === order?.sampleCollectionPartner?.id) {
            message.warning('Please select a different sample collector');
            return;
          }

          if (!forcedSampleCollectorId) {
            message.warning('Please select a different sample collector');
            return;
          }
          const now = new Date();
          const cancelDate = new Date(order.cancellableUpTo);
          const remainingSec = (cancelDate - now) / 1000;
          if (cancelDate > now) {
            message.error(`Can't re-assign till ${remainingSec} secs`);
            return;
          }
          const key = 'updatable';
          message.open({
            key,
            type: 'loading',
            content: 'Replacing collectors...',
            duration: 0
          });
          await replaceSampleCollectorForce({
            orderId: order.id,
            sampleCollectorId: forcedSampleCollectorId,
            data: currentSlot.date,
            time: currentSlot.time
          });
          setForcedSampleCollectorId(null);
          setForcedReplace(false);
          message.destroy(key);
          fetchOrder(order.id);
        }}
      >
        <Divider />
        <div className="forced-replace-sample-collector">
          <Select
            onChange={(change) => {
              setForcedSampleCollectorId(change);
            }}
            optionLabelProp="label"
            disabled={!isAllowedToReassign}
            placeholder={order?.sampleCollectionPartner?.name}
          >
            {allSampleCollectorsOptions}
          </Select>
        </div>
        <Divider />
      </Modal>
      {/* REJECTION SINGLE MODAL */}
      <Modal
        title={<div>Rejection reason</div>}
        open={openRejectionModal}
        cancelButtonProps={{ className: 'no-display' }}
        okButtonProps={{
          className: 'no-display'
        }}
        footer={null}
        onCancel={() => setOpenRejectionModal(false)}
        onOk={() => setOpenRejectionModal(false)}
      >
        <Form layout="vertical" onFinish={onRejectionFormSubmit}>
          <Form.Item label="Reason" name="rejectionReason" rules={[required]}>
            <Input />
          </Form.Item>
          <Button
            loading={isRejecting}
            htmlType="submit"
            className="create-btn-modal"
          >
            Reject
          </Button>
        </Form>
      </Modal>
      {/* LOGS MODAL HERE */}
      <Modal
        title={<div>Logs</div>}
        open={isLogModalOpen}
        cancelButtonProps={{ className: 'no-display' }}
        okButtonProps={{
          type: 'primary',
          className: 'cancel-modal-btn'
        }}
        onCancel={() => setIsLogModalOpen(false)}
        onOk={() => setIsLogModalOpen(false)}
      >
        {allLogs.map((log) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <div className="log-row">
              <div className="time-log">
                {moment(log.updatedAt).format('D/MM/YYYY')} |{' '}
                {moment(log.updatedAt).format('hh:mm a')}
              </div>
              <div
                className="message-log"
                dangerouslySetInnerHTML={{ __html: log.message }}
              />
            </div>
          );
        })}
      </Modal>

      {/* CANCEL MODAL HERE */}
      <Modal
        title={
          <div>
            <Space className="warning-container">
              <WarningOutlined />
              Cancel Order
            </Space>
          </div>
        }
        centered
        closeIcon={<CloseCircleOutlined className="close-btn" />}
        open={isCancelModal}
        okButtonProps={{
          type: 'primary',
          danger: 'danger',
          className: 'no-display'
        }}
        cancelButtonProps={{ className: 'no-display' }}
        okText="Cancel order"
        // onOk={() => cancelOrder()}
        onCancel={() => setIsCancelModal(false)}
      >
        <Divider />
        <p>
          Are you sure you want to cancel <b>{order && order.patient.name}'s</b>
          &nbsp;order and refund it?
        </p>
        <Divider />
        <div className="cancel">
          <Button
            onClick={() => {
              cancelOrder();
            }}
            className="cancel-modal-btn"
          >
            Cancel order
          </Button>
        </div>
      </Modal>
      {/* DETAILS MODAL HERE */}
      <Modal
        title="Order Details"
        open={viewDetails}
        onCancel={handleCancelDetails}
        footer={null}
        centered
        className="details-modal"
      >
        <Divider className="divider-details" />

        <div
          ref={componentRef}
          style={{
            paddingTop: '10px',
            marginLeft: '30px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%'
          }}
          className="print-details"
        >
          <Typography.Title
            style={{
              fontSize: '30px',
              textAlign: 'center',
              textDecoration: 'underline',
              paddingBottom: '20px',
              paddingRight: '50px'
            }}
          >
            DIAGNOSTICS AT DOORSTEP
          </Typography.Title>
          <div>
            <Typography.Title
              style={{
                fontSize: '18px'
              }}
            >
              PATIENT DETAILS
            </Typography.Title>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                fontSize: '16px',
                paddingLeft: '10px'
              }}
            >
              <span>
                <b> PATIENT NAME</b> : {order?.patient?.name}
              </span>
              <span>
                <b> PATIENT AGE</b> : {order?.patient?.age}
              </span>
              <span>
                <b> PATIENT GENDER</b> : {order?.patient?.sex}
              </span>
              <span>
                <b> ORDER ID</b> : {order?.orderId}
              </span>
              <span>
                <b> ADDRESS</b> : {order?.address?.addressLine1},
                {order?.address?.addressLine2},{order?.address?.state},
                {order?.address?.city},{order?.address?.pincode}
              </span>
              <span>
                <b> BOOKED ON</b> :{' '}
                {dayjs(order?.scheduledAt).format('DD/MM/YYYY')}
              </span>
            </div>
          </div>
          <div>
            <Typography.Title
              style={{
                fontSize: '18px'
              }}
            >
              TESTS DETAILS
            </Typography.Title>
            {order?.orderSummary?.tests?.map((test, index) => {
              return (
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: '16px',
                    paddingLeft: '10px'
                  }}
                  className="tests"
                  key={test.testId}
                >
                  <b>
                    {index + 1}) {test.title}
                  </b>
                </span>
              );
            })}
            <span
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '10px',
                fontSize: '16px'
              }}
            >
              <Typography.Title
                style={{
                  fontSize: '16px',
                  textDecoration: 'underline'
                }}
              >
                SAMPLES COLLECTED
              </Typography.Title>
              {order?.requiredSamples?.map((samp) => {
                // eslint-disable-next-line react/jsx-key
                return (
                  // eslint-disable-next-line react/jsx-key
                  <span
                    style={{
                      display: 'flex',
                      paddingLeft: '10px'
                    }}
                  >
                    <b>{samp.sample.name}</b> :&nbsp;
                    {samp.linkedEquipment.join(',')}
                  </span>
                );
              })}
            </span>
          </div>
          <div className="sample-details">
            <Typography.Title
              style={{
                fontSize: '18px'
              }}
            >
              SAMPLE COLLECTION DETAILS
            </Typography.Title>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                fontSize: '16px',
                paddingLeft: '10px'
              }}
            >
              <span>
                <b> SAMPLE COLLECTED ON</b> :&nbsp;
                {!order?.sampleCollectedAt
                  ? 'Not yet collected'
                  : dayjs(order?.sampleCollectedAt).format('DD/MM/YYYY')}
              </span>
              <span>
                <b> SAMPLE COLLECTED TIME</b> :&nbsp;
                {!order?.sampleCollectedAt
                  ? 'Not yet collected'
                  : dayjs(order?.sampleCollectedAt).format('hh:mm a')}
              </span>
            </div>
          </div>
        </div>

        <Divider />
        <div className="print">
          <Button
            onClick={() => {
              setViewDetails(false);
              handlePrint();
            }}
            className="create-btn print-btn"
          >
            PRINT
          </Button>
        </div>
      </Modal>
      {/* RESCHEDULE MODAL HERE */}
      <Modal
        className="reschedule"
        title={
          currentDate && (
            <div className="reschedule-title-section">
              <Button
                type="text"
                onClick={() => {
                  setCurrentDate((prev) => {
                    const newDate = addDays(prev, -1);
                    setSlotForReschedule(undefined);
                    monitorSlots(newDate);
                    setRefetchingAvailableSlots(true);
                    setIsRescheduleModal(true);
                    return newDate;
                  });
                }}
                style={{
                  visibility:
                    currentDate &&
                    getDifferenceInDays(currentDate, new Date()) === 0
                      ? 'hidden'
                      : ''
                }}
              >
                <Backward className="btn" />
              </Button>
              <Space className="warning-container">
                <span>{formatDate(currentDate.toString(), 'DD/MM/YYYY')}</span>
              </Space>
              <Button
                type="text"
                onClick={() => {
                  setCurrentDate((prev) => {
                    const newDate = addDays(prev, 1);
                    setSlotForReschedule(undefined);
                    monitorSlots(newDate);
                    setRefetchingAvailableSlots(true);
                    setIsRescheduleModal(true);
                    return newDate;
                  });
                }}
              >
                <Forward className="btn" />
              </Button>
            </div>
          )
        }
        centered
        closable={false}
        open={isRescheduleModal}
        okButtonProps={{
          type: 'primary',
          className: 'reschedule-modal-btn',
          disabled: slotForReschedule ? '' : 'disabled',
          loading: isLoadingRescheduling
        }}
        onOk={async () => {
          const { id } = slotForReschedule.slot;
          const formatted = getDateIntoDDMMYYYY(currentDate);
          setIsLoadingRescheduling(true);
          const response = await rescheduleSampleCollector(
            order.id,
            id,
            formatted
          );
          setIsLoadingRescheduling(false);
          const key = 'updatable';
          if (response) {
            message.open({
              key,
              type: 'success',
              content: 'Rescheduled',
              duration: 1
            });
            fetchOrder(order.id);
            setIsRescheduleModal(false);
          } else {
            message.error('Failed to reschedule');
          }
        }}
        cancelButtonProps={{ className: 'no-display' }}
        okText="Reschedule"
        onCancel={() => setIsRescheduleModal(false)}
      >
        {currentDate && (
          <div className="reschedule-container">
            <Typography.Text>Select time to reschedule</Typography.Text>
            <div className="available-times">
              {refetchingAvailableSlots ? (
                <Spin size="large" />
              ) : (
                <>
                  <div>
                    <div className="event-type">
                      <MorningImage /> Morning
                    </div>
                    <Row>
                      {availableSlots.filter(
                        (availableSlot) => availableSlot.slot.isMorning
                      ).length < 1
                        ? 'No morning slots available '
                        : availableSlots
                            .filter(
                              (availableSlot) => availableSlot.slot.isMorning
                            )
                            .map((availableSlot) => {
                              const { slot, selected, available } =
                                availableSlot;
                              return (
                                <Col
                                  className="forHover"
                                  key={slot.id}
                                  onClick={
                                    available
                                      ? () => {
                                          let selectedIndex = 0;
                                          const tempAvailableSlots =
                                            availableSlots.slice();
                                          for (
                                            let i = 0;
                                            i < tempAvailableSlots.length;
                                            i += 1
                                          ) {
                                            tempAvailableSlots[i] = {
                                              ...tempAvailableSlots[i],
                                              selected: false
                                            };
                                            if (
                                              tempAvailableSlots[i].slot.id ===
                                              slot.id
                                            ) {
                                              tempAvailableSlots[
                                                i
                                              ].selected = true;
                                              selectedIndex = i;
                                            }
                                          }
                                          setAvailableSlots(tempAvailableSlots);
                                          setSlotForReschedule(
                                            tempAvailableSlots[selectedIndex]
                                          );
                                        }
                                      : null
                                  }
                                >
                                  <div
                                    className={`time-slot${
                                      selected ? ' selected-slot' : ''
                                    }${available ? '' : ' disabled-slot'}`}
                                  >
                                    <p>{slot.name.toUpperCase()}</p>
                                  </div>
                                </Col>
                              );
                            })}
                    </Row>
                  </div>
                  <div>
                    <div className="event-type">
                      <AfternoonImage /> Afternoon
                    </div>
                    <Row>
                      {availableSlots.filter(
                        (availableSlot) => availableSlot.slot.isAfternoon
                      ).length < 1
                        ? 'No afternoon slots available '
                        : availableSlots
                            .filter(
                              (availableSlot) => availableSlot.slot.isAfternoon
                            )
                            .map((availableSlot) => {
                              const { slot, selected, available } =
                                availableSlot;
                              return (
                                <Col
                                  className="forHover"
                                  key={slot.id}
                                  onClick={
                                    available
                                      ? () => {
                                          let selectedIndex = 0;
                                          const tempAvailableSlots =
                                            availableSlots.slice();
                                          for (
                                            let i = 0;
                                            i < tempAvailableSlots.length;
                                            i += 1
                                          ) {
                                            tempAvailableSlots[i] = {
                                              ...tempAvailableSlots[i],
                                              selected: false
                                            };
                                            if (
                                              tempAvailableSlots[i].slot.id ===
                                              slot.id
                                            ) {
                                              tempAvailableSlots[
                                                i
                                              ].selected = true;
                                              selectedIndex = i;
                                            }
                                          }
                                          setAvailableSlots(tempAvailableSlots);
                                          setSlotForReschedule(
                                            tempAvailableSlots[selectedIndex]
                                          );
                                        }
                                      : null
                                  }
                                >
                                  <div
                                    className={`time-slot${
                                      selected ? ' selected-slot' : ''
                                    }${available ? '' : ' disabled-slot'}`}
                                  >
                                    <p>{slot.name.toUpperCase()}</p>
                                  </div>
                                </Col>
                              );
                            })}
                    </Row>
                  </div>
                  <div>
                    <div className="event-type">
                      <EveningImage /> Evening
                    </div>
                    <Row>
                      {availableSlots.filter(
                        (availableSlot) => availableSlot.slot.isEvening
                      ).length < 1
                        ? 'No evening slots available '
                        : availableSlots
                            .filter(
                              (availableSlot) => availableSlot.slot.isEvening
                            )
                            .map((availableSlot) => {
                              const { slot, selected, available } =
                                availableSlot;
                              return (
                                <Col
                                  className="forHover"
                                  key={slot.id}
                                  onClick={
                                    available
                                      ? () => {
                                          let selectedIndex = 0;
                                          const tempAvailableSlots =
                                            availableSlots.slice();
                                          for (
                                            let i = 0;
                                            i < tempAvailableSlots.length;
                                            i += 1
                                          ) {
                                            tempAvailableSlots[i] = {
                                              ...tempAvailableSlots[i],
                                              selected: false
                                            };
                                            if (
                                              tempAvailableSlots[i].slot.id ===
                                              slot.id
                                            ) {
                                              tempAvailableSlots[
                                                i
                                              ].selected = true;
                                              selectedIndex = i;
                                            }
                                          }
                                          setAvailableSlots(tempAvailableSlots);
                                          setSlotForReschedule(
                                            tempAvailableSlots[selectedIndex]
                                          );
                                        }
                                      : null
                                  }
                                >
                                  <div
                                    className={`time-slot${
                                      selected ? ' selected-slot' : ''
                                    }${available ? '' : ' disabled-slot'}`}
                                  >
                                    <p>{slot.name.toUpperCase()}</p>
                                  </div>
                                </Col>
                              );
                            })}
                    </Row>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}
export default OrderReport;
